export const tamilContent = {
  no_data_found: "தகவல் இல்லை",
  no_data_found_add_data: 'தகவல் சேர்க்க "Add Button" ஐ கிளிக் செய்க ',
  amount: "தொகை",
  all: "அனைத்து",
  dashboard: "டாஷ்போர்டு",
  donation: "தானம்",
  commitment: "உறுதி",
  hundi: "தானப்பெட்டி சேகரிப்புகள்",
  financial_reports: "நிதி அறிக்கைகள்",
  internal_expenses: "செலவுகள்",
  total_donation: "மொத்த தானம்:",
  events: "நிகழ்வுகள்",
  total: "மொத்தம்:",
  Select: "தேர்வு செய்க",
  news: "செய்திகள்",
  notices: "அறிவிப்புகள்",
  configuration: "உள்ளமைவு",
  searchBarPlaceHolder: "கோயில்கள், அறக்கட்டளை, நிகழ்வுகள், செய்திகள் தேடு",
  monthName_January: "ஜனவரி",
  monthName_February: "பிப்ரவரி",
  monthName_March: "மார்ச்",
  monthName_April: "ஏப்ரல்",
  monthName_May: "மே",
  monthName_June: "ஜூன்",
  monthName_July: "ஜூலை",
  monthName_August: "ஆகஸ்ட்",
  monthName_September: "செப்டம்பர்",
  monthName_October: "அக்டோபர்",
  monthName_November: "நவம்பர்",
  monthName_December: "டிசம்பர்",
  last_login: "இறுதியாக உள்நுழைந்தது",
  category: "வகை",
  user: "உப நிர்வாகி",
  report_Dispute: "அறிக்கை மற்றும் முரண்பாடு",
  cancel: "ரத்து செய்க",
  confirm: "நிர்ணயம் நீக்குவது உறுதி செய்க",
  save_changes: "மாற்றங்களைச் சேமிக்கவும்",
  rupees: "ரூ",
  downloadReceipt: "ரசீது பதிவிறக்குக",
  pay_donation: "தானம் செலுத்துக",
  payment: "இப்போது செலுத்துக",
  paymentPaid: "செலுத்தப்பட்டது",
  injection_found: "சிறப்பு எழுத்துக்கள் ஆதரிக்கப்படவில்லை",
  logout_msg: "உங்களுக்கு வெளிச்செல்ல விருப்பமா?",
  no: "இல்லை",
  yes: "ஆம்",
  tags_limit: "குறிச்சொற்களின் வரம்பு மீறப்பட்டது",
  end_time_less:
    "முடிவின் நேரம் தொடங்கும் நேரத்தை விட பெரியதாக இருக்க வேண்டும்",
  publish_now: "இப்போதே வெளியீடு செய்க",
  already_publish: "செய்தி ஏற்கனவே வெளியிடப்பட்டுள்ளது.",
  already_publish_notice: "அறிவிப்பு ஏற்கனவே வெளியிடப்பட்டுள்ளது.",
  unPublish: "வெளியீடு நீக்குக",
  schedule: "அட்டவணை",
  reSchedule: "மீண்டும் அட்டவணையிடுக",
  publish: "வெளியீடு செய்க",
  published: "வெளியிடப்பட்டது",
  invalid_amount: "தவறான மதிப்பு",
  tagsChar_limit:
    "குறிச்சொல் களத்தில் 20 எழுத்துகளின் வரம்பு உள்ளது மற்றும் அதற்கு மேல் ஏற்க முடியாது.",
  // All static content of Dashboard
  dashboard_donationReceived: "தானம் பெறப்பட்டது",
  dashboard_donationPending: "நிலுவையிலுள்ள தானம்",
  dashboard_all: "அனைத்து",
  donation_hundi: "தானம் + ஹூண்டி",
  dashboard_totalExpenses: "மொத்த செலவுகள்",
  noTopDonar: "மீட்டப்பட்டது இல்லை",
  dashboard_RevenueReport: "நிதி அறிக்கை",
  notification_not_found: "எந்த அறிவிப்பும் கிடைக்கவில்லை",
  dashboard_monthly: "இந்த மாதம்",
  dashboard_weekly: "இந்த வாரம்",
  dashboard_yearly: "இந்த ஆண்டு",
  dashboard_daily: "நாளொன்றுக்கு",

  dashboard_card_title3: "சந்தாதாரர்கள்",
  dashboard_Recent_DonationCommitment: "சமீபத்திய தானங்கள்",
  dashboard_Recent_DonorName: "தானம் வழங்குநரின் பெயர்",
  dashboard_Recent_DonorNumber: "மொபைல் எண்",
  dashboard_Recent_DonorType: "வகை",
  dashboard_Recent_DonorDate: "தேதி & நேரம்",
  dashboard_Recent_DonorStatus: "நிலைமை",
  dashboard_Recent_DonorAmount: "தொகை",
  dashboard_Recent_DonorCommitId: "உறுதிசெய்த ஐடி",
  dashboard_Recent_DonorReceipt: "ரசீது",
  dashboard_viewAll: "அனைத்தையும் காண்க",
  dashboard_top: "முன்னணி 5 தான தாரர்கள்",

  // All static content of News

  news_AddNews: "செய்தி சேர்க்கவும்",
  news_EditNews: "செய்தி திருத்தவும்",
  news_AddLangNews: "மொழி சேர்க்கவும்",
  news_InputIn: "மொழி தேர்வு செய்க:",
  news_label_Title: "தலைப்பு",
  news_label_Tags: "குறிச்சொற்கள்",
  news_label_Description: "விளக்கம் சேர்க்கவும்",
  news_label_Date: "தேர்ந்தெடுக்கப்பட்ட தேதி",
  news_label_Published: "வெளியிட்டவர்",
  news_label_ImageVedio: "படங்களைச் சேர்க்கவும்",
  news_button_Publish: "வெளியீடு",
  news_title_required: "தலைப்பு தேவை",
  news_tags_required: "குறிச்சொற்கள் தேவை",
  news_desc_required: "விளக்கம் தேவை",
  news_publish_required: "வெளியீட்டின் பெயர் தேவை",
  news_latest_news: "செய்திகள்",
  news_btn_AddNews: "செய்தி சேர்க்கவும்",
  news_popOver_AddLang: "மொழி சேர்க்கவும்",
  news_popOver_Edit: "திருத்து",
  news_popOver_Delete: "நீக்கு",

  news_delete: "செய்தி நீக்கு",
  news_sure: "இந்த செய்தியை நிரந்தரமாக நீக்க விரும்புகிறீர்களா?",
  news_not_found: "செய்தி இல்லை",
  news_not_click_add_news:
    "புதிய செய்தி கட்டுரையைச் சேர்க்க 'செய்தி சேர்க்கவும்' என்பதை கிளிக் செய்க.",

  // All static content of Events
  same_time: "முடிவின் நேரம் தொடங்கும் நேரத்தைப் போல இருக்க முடியாது",
  events_AddEvent: "நிகழ்வு சேர்க்கவும்",
  events_latest_event: "சமீபத்திய நிகழ்வுகள்",
  events_title_required: "தலைப்பு தேவை",
  events_desc_required: "விளக்கம் தேவை",
  events_select_dropDown: "நிகழ்வை தேர்ந்தெடுக்கவும்",
  events_select_globle: "சர்வதேச நிகழ்வை தேர்ந்தெடுக்கவும்",
  events_EditEvent: "நிகழ்வை திருத்தவும்",
  events_startTime_required: "தொடக்க நேரம் தேவை",
  events_endTime_required: "முடிவு நேரம் தேவை",
  events_startDate_required: "தொடக்க தேதி தேவை",
  events_endDate_required: "முடிவு தேதி தேவை",
  events_delete: "நிகழ்வு நீக்கு",
  events_sure: "இந்த நிகழ்வை நிரந்தரமாக நீக்க விரும்புகிறீர்களா?",
  events_not_found: "நிகழ்வுகள் இல்லை",
  events_not_click_add_events:
    "புதிய நிகழ்வைச் சேர்க்க 'நிகழ்வு சேர்க்கவும்' என்பதை கிளிக் செய்க.",

  // All static content of Pashudhan
  cattles_management: "கோசாலை",
  cattle_expense_type_assets: "சொத்துக்கள்",
  cattle_dead: "இறந்தது",
  cattle_alive: "வாழ்ந்து",
  cattle_expense_type_consumable: "நுகரக்கூடியது",
  cattle_expense_type_general: "பொதுவானது",
  cattle_info: "பசுபொருள் தகவல்",
  cattle_photo: "பசுபொருளின் புகைப்படம்",
  cattle_owner_photo: "உரிமையாளர் புகைப்படம்",
  cattle_owner_id: "உரிமையாளர் ஐடி",
  cattle_mother_id: "தாயின் குறிச்சொல் ஐடி",
  cattle_breed: "இனப்பெருக்கம்",
  cattle_date_of_birth: "பிறந்த தேதி",
  cattle_age: "வயது",
  cattle_is_pregnant: "கர்ப்பமாக இருக்கிறதா",
  cattle_is_milking: "பால் கொடுப்பதா",
  cattle_milk_quantity: "பாலின் அளவு",
  cattle_fees: "மருத்துவ செலவுகள்",
  cattle_medical_record: "மருத்துவ பதிவுகள்",
  cattle_pregnancy_record: "கர்ப்ப பதிவுகள்",
  cattle_expense: "செலவுகள் மேலாண்மை",
  cattle_stock: "பங்கு மேலாண்மை",
  cattle_usage: "பயன்பாடு மேலாண்மை",

  cattle_revenueHeading: "தினசரி பதிவுப் பதிவு",
  recent_registered_cattles: "சமீபத்தில் பதிவுசெய்யப்பட்ட பசுபொருள்",
  cattle_registered: "பதிவுசெய்யப்பட்ட பசுபொருள்",
  cattle_add: "பசுபொருள் சேர்க்கவும்",
  cattle_record_add: "பதிவைச் சேர்க்கவும்",
  cattle_breed_add: "இனப்பெருக்கத்தைச் சேர்க்கவும்",
  cattle_breed_edit: "இனப்பெருக்கத்தை திருத்தவும்",
  cattle_medical_add: "மருத்துவப் பதிவைச் சேர்க்கவும்",
  cattle_pregnancy_report: "கர்ப்ப பதிவுகள்",
  cattle_pregnancy_report_add: "கர்ப்பப் பதிவைச் சேர்க்கவும்",
  cattle_expense_add: "செலவுகளைச் சேர்க்கவும்",
  cattle_supplies_add: "பொருட்களைச் சேர்க்கவும்",
  cattle_items_add: "பொருட்களைச் சேர்க்கவும்",
  cattle_id: "பசுபொருள் ஐடி",
  calf_id: "கன்றின் ஐடி",
  cattle_treatment_medicine: "சிகிச்சை/மருந்து",
  cattle_dosage: "அளவு",
  cattle_dr_name: "மருத்துவரின் பெயர்",
  cattle_purchase_price: "கொள்முதல் விலை",
  cattle_symptoms: "அறிகுறிகள்",
  cattle_usage_add: "பயன்பாட்டைச் சேர்க்கவும்",
  cattles: "பசுபொருள்",
  cattles_feed: "உணவு",
  cattles_medical: "மருத்துவம்",
  cattles_death: "இறப்பு",
  cattles_machinery: "எந்திரம்",
  cattle_conceiving_date: "கருவுறும் தேதி",
  cattle_pregnancy_date: "கர்ப்ப நாள்",
  cattle_delivery_date: "பிறப்பு தேதி",
  cattle_pregnancy_status: "கர்ப்ப நிலை",
  cattle_purpose: "நோக்கம்",
  cattle_itemId: "பொருள் ஐடி",
  cattle_itemName: "பொருள் பெயர்",
  cattle_expense_quantity: "அளவு",
  cattle_expense_bill_invoice: "பில்/விலைப்பட்டியல்",
  cattle_expense_bill_invoice_upload: "பதிவேற்றுக",
  cattle_unit: "அலகு",
  cattle_expense_unitType: "அலகு வகை",
  cattle_expense_current_quantity: "தற்போதைய அளவு",
  cattle_expense_order_quantity: "ஆர்டர் அளவு",
  cattle_last_update: "கடைசி புதுப்பிக்கப்பட்டது",
  cattle_tag_id: "குறிச்சொல் ஐடி",
  cattle_date_purchased: "கொள்முதல் தேதி",
  cattle_source: "மூலம்",
  cattle_owner_name: "உரிமையாளர்/காவலரின் பெயர்",
  cattle_owner_number: "உரிமையாளர்/காவலரின் எண்",
  cattle_owner_image: "உரிமையாளர்/காவலரின் படம்",
  cattle_cow_image: "பசுமாடு படம்",
  cattle_is_dead: "இறந்தது",
  cattle_date_death: "இறப்பு தேதி",
  cattle_death_reason: "இறப்பு காரணம்",
  cattle_is_sold: "விற்கப்பட்டுள்ளது",
  cattle_purchaser_name: "வாங்குநரின் பெயர்",
  cattle_purchaser_number: "வாங்குநரின் எண்",
  cattle_purchaser_id: "வாங்குநரின் ஐடி",
  cattle_sold_date: "விற்கும் தேதி",
  cattle_sold_price: "விற்கும் விலை",
  cattle_edit_medical: "மருத்துவப் பதிவை திருத்து",
  cattle_edit_pregnancy: "கர்ப்பப் பதிவை திருத்து",
  cattle_edit_cattle: "பசுபொருளை திருத்து",
  cattle_edit_item: "பொருளை திருத்து",
  cattle_edit_supplies: "சேமிப்புகளை திருத்து",
  cattle_edit_usage: "பயன்பாட்டை திருத்து",
  cattle_category_edit: "வகையை திருத்து",
  cattle_category_add: "வகையைச் சேர்க்கவும்",

  // cattle validation messages
  cattle_itemID_required: "பொருள் ஐடி தேவை",
  cattle_id_required: "பசுபொருள் ஐடி தேவை",
  cattle_pregnancy_status_required: "கர்ப்ப நிலை தேவை",
  cattle_name_required: "பெயர் தேவை",
  cattle_order_quantity_required: "ஆர்டர் அளவு தேவை",
  cattle_unit_required: "அலகு தேவை",
  cattle_unit_type_required: "அலகு வகை தேவை",
  cattle_quantity_required: "அளவு தேவை",
  cattle_purpose_required: "நோக்கம் தேவை",
  cattle_treatment_medicine_required: "சிகிச்சை/மருந்து தேவை",
  cattle_dosage_required: "அளவு தேவை",
  cattle_DrName_required: "மருத்துவரின் பெயர் தேவை",
  cattle_price_required: "மருத்துவ செலவுகள் தேவை",
  cattle_symptoms_required: "அறிகுறிகள் தேவை",
  cattle_tag_id_required: "குறிச்சொல் ஐடி தேவை",
  cattle_type_required: "வகை தேவை",
  cattle_breed_required: "இனப்பெருக்கம் தேவை",
  cattle_age_required: "வயது தேவை",
  cattle_purchase_price_required: "கொள்முதல் விலை தேவை",
  cattle_source_required: "மூலம் தேவை",
  cattle_owner_name_required: "உரிமையாளர் பெயர் தேவை",
  cattle_owner_id_required: "உரிமையாளர் ஐடி தேவை",
  cattle_deathReason_required: "இறப்பு காரணம் தேவை",
  cattle_purchaser_name_required: "வாங்குநரின் பெயர் தேவை",
  cattle_purchaser_id_required: "வாங்குநரின் ஐடி தேவை",
  cattle_sold_price_required: "விற்கும் விலை தேவை",
  cattle_milk_quantity_required: "பாலின் அளவு தேவை",

  // all cattle management delete messages
  cattle_cattle_delete: "பசுபொருளை நீக்கு",
  cattle_cattle_sure: "இந்த பசுபொருளை நீக்க விரும்புகிறீர்களா?",
  cattle_medical_delete: "மருத்துவ தகவலை நீக்கு",
  cattle_medical_sure: "இந்த மருத்துவ அறிக்கையை நீக்க விரும்புகிறீர்களா?",
  cattle_pregnancy_delete: "கர்ப்ப அறிக்கையை நீக்கு",
  cattle_pregnancy_sure: "இந்த கர்ப்ப அறிக்கையை நீக்க விரும்புகிறீர்களா?",
  cattle_supplies_delete: "சேமிப்புகளை நீக்கு",
  cattle_supplies_sure: "இந்த சேமிப்புகளை நீக்க விரும்புகிறீர்களா?",

  cattle_usage_delete: "பயன்பாட்டை நீக்கு",
  cattle_usage_sure: "இந்த பயன்பாட்டை நீக்க விரும்புகிறீர்களா?",

  cattle_item_delete: "பொருளை நீக்கு",
  cattle_item_sure: "இந்த பொருளை நீக்க விரும்புகிறீர்களா?",
  cattle_breed_delete: "இனப்பெருக்கத்தை நீக்கு",
  cattle_breed_sure: "இந்த இனப்பெருக்கத்தை நீக்க விரும்புகிறீர்களா?",

  // All cattle search variable
  search_cattles: "பசுபொருள் ஐடி, வகை, இனப்பெருக்கம் மூலம் தேடு",
  search_cattles_medical: "பசுபொருளின் மருத்துவ தகவலை தேடு",
  search_cattles_pregnancy: "பசுபொருளின் கர்ப்ப அறிக்கையை தேடு",
  search_cattles_stock: "பங்குகளை தேடு",
  search_cattles_supplies: "பொருள் சேமிப்புகளை தேடு",
  search_cattles_usage: "பொருள் பயன்பாடுகளை தேடு",
  search_cattles_items: "பசுபொருள் பொருளை தேடு",
  // All static content of Notices

  notices_AddNotice: "அறிவிப்பு சேர்க்கவும்",
  notices_latest_Notice: "அறிவிப்புகள்",
  add_image: "படத்தைச் சேர்க்கவும்",
  image_size_suggestion: "(450px*350px அளவிலான படத்தை பதிவேற்றுக)",
  notices_title_required: "தலைப்பு தேவை",
  notices_desc_required: "விளக்கம் தேவை",
  notices_select_dropDown: "அறிவிப்பைத் தேர்ந்தெடுக்கவும்",
  notices_EditNotice: "அறிவிப்பைத் திருத்து",

  notices_delete: "அறிவிப்பை நீக்கு",
  notices_sure: "இந்த அறிவிப்பை நிரந்தரமாக நீக்க விரும்புகிறீர்களா?",
  notices_not_found: "அறிவிப்புகள் இல்லை",
  notices_not_click_add_notices:
    "புதிய அறிவிப்பை சேர்க்க 'அறிவிப்பு சேர்க்கவும்' என்பதை கிளிக் செய்க.",

  // All static content of Categories
  only_letters: "உப வகை எழுத்து எழுத்துகளை மட்டுமே கொண்டிருக்கும்",
  categories_AddCategory: "உப வகையைச் சேர்க்கவும்",
  categories_serial_number: "வரிசை எண்",
  categories_master_category: "முதன்மை வகை",
  categories_sub_category: "உப வகை",
  categories_category_required: "முதன்மை வகை தேவை",
  categories_sub_category_required: "உப வகை தேவை",
  categories_latest_Category: "வகைகள்",
  categories_title_required: "வகை பெயர் தேவை",
  categories_EditCategory: "வகையைத் திருத்து",
  categories_select_category: "வகையைத் தேர்ந்தெடுக்கவும்",
  categories_select_amount: "தொகையை உள்ளிடவும்",
  category_select_sub_category: "உப வகையைத் தேர்ந்தெடுக்கவும்",
  categories_select_user_name: "பயனரின் பெயரைத் தேர்ந்தெடுக்கவும்",
  category_select: "தேர்வுசெய்",
  categories_select_master_category: "முதன்மை வகையைத் தேர்ந்தெடுக்கவும்",
  donar_name_required: "தான நபரின் பெயர் தேவை",
  created_by_required: "உருவாக்கியவர் தேவை",
  user_select_required: "பயனர் பெயர் தேவை",
  masterCategory_required: "வகை தேவை",
  subCategory_required: "உப வகையைத் தேர்ந்தெடுக்கவும்",
  commitmentID_required: "உறுதி ஐடியைத் தேர்ந்தெடுக்கவும்",
  amount_required: "தொகை தேவை",

  category_delete: "வகையை நீக்கு",
  category_sure: "இந்த வகையை நிரந்தரமாக நீக்க விரும்புகிறீர்களா?",
  category_not_found: "வகைகள் கிடைக்கவில்லை",
  category_not_click_add_category:
    "புதிய வகையை அறிமுகப்படுத்த 'வகையைச் சேர்க்கவும்' என்பதைக் கிளிக் செய்க.",

  // All static content of Users
  users_AddUser: "உப நிர்வாகியைச் சேர்க்கவும்",
  users_latest_User: "உப நிர்வாகி",
  users_title_required: "பயனர் பெயர் தேவை",
  user_only_letters: "பயனர் பெயர் எழுத்துகளை மட்டுமே கொண்டிருக்க வேண்டும்",
  users_mobile_required: "மொபைல் எண் தேவை",
  mobile_number_limit: "மொபைல் எண் 10 இலக்கங்கள் இருக்க வேண்டும்",
  users_email_required: "மின்னஞ்சல் தேவை",
  users_not_found: "உப நிர்வாகிகள் கிடைக்கவில்லை",
  users_not_click_add_users:
    "புதிய உப நிர்வாகியைச் சேர்க்க 'உப நிர்வாகியைச் சேர்க்கவும்' என்பதைக் கிளிக் செய்க.",

  // All static content of Internal Expense
  expenses_AddExpenses: "செலவுகளைச் சேர்க்கவும்",
  price_per_item: "ஒரு பொருளின் விலை",
  expense_type_required: "செலவின் வகை தேவை",
  expenses_EditExpenses: "செலவுகளைத் திருத்து",
  expenses_Date: "தேதி",
  expence_description: "விளக்கம்",
  expenses_latest_Expenses: "அனைத்து செலவுகள்",
  expenses_title_required: "தலைப்பு தேவை",
  expenses_mobile_required: "மொபைல் எண் தேவை",
  expenses_email_required: "மின்னஞ்சல் தேவை",
  added_by: "உருவாக்கியவர்",
  expenses_desc_required: "விளக்கம் தேவை",
  expence_amount_required: "தொகை தேவை",

  expence_delete: "செலவுகளை நீக்கு",
  expence_sure: "இந்த செலவுகளை நிரந்தரமாக நீக்க விரும்புகிறீர்களா?",
  expence_not_found: "செலவுகள் கிடைக்கவில்லை",
  expence_not_click_add_expence:
    "புதிய செலவுகளை பதிவுசெய்ய 'செலவுகளைச் சேர்க்கவும்' என்பதைக் கிளிக் செய்க.",

  // All static content of Financial Report
  report_AddReport: "நிதி அறிக்கைகள்",
  report_expences: "செலவுகள்",
  remarks_financial_donationBox: "குறிப்புகள்",
  report_commitment: "உறுதி",
  reports_not_found: "அறிக்கை மற்றும் முரண்பாடு இல்லை",
  report_donation_box: "தானப்பெட்டி சேகரிப்புகள்",
  finance_report_no_found:
    "தேர்ந்தெடுத்த காலவரிசைக்கு எந்த நிதி அறிக்கையும் கிடைக்கவில்லை",
  financial_not_found: "நிதி இல்லை",

  // All static content of Donation
  donation_Donation: "தானம்",
  start_Time: "தொடக்க நேரம்",
  end_Time: "முடிவு நேரம்",
  is_government: "அரசு தான நபர்",
  donation_Adddonation: "தானம் சேர்க்கவும்",
  original_amount: "அசல் தொகை",
  estimate_amount: "மதிப்பீடு தொகை",
  donation_select_date_time: "தேதியைத் தேர்ந்தெடுக்கவும்",
  donation_select_date: "தேதியைத் தேர்ந்தெடுக்கவும்",
  donation_donar_name_only_letters:
    "தான நபரின் பெயர் எழுத்துகளை மட்டுமே கொண்டிருக்க வேண்டும்",
  add_user_donation: "பயனர் கிடைக்கவில்லை, இங்கே கிளிக் செய்க",
  donation_delete: "தானத்தை நீக்கு",
  donation_sure: "இந்த தானத்தை நிரந்தரமாக நீக்க விரும்புகிறீர்களா?",
  donation_not_found: "தானம் கிடைக்கவில்லை",
  add_user: "பயனர் சேர்க்கவும்",
  donation_paid_not_found: "செலுத்திய தானங்கள் கிடைக்கவில்லை",
  donation_not_click_add_donation:
    'தானத்தைச் சேர்க்க "தானம் சேர்க்கவும்" என்பதைக் கிளிக் செய்க.',

  // All static content of Donation Box
  DonationBox_total_collection: "மொத்த சேகரிப்பு:",
  DonationBox_DonationBox: "தானப்பெட்டி சேகரிப்புகள்",
  DonationBox_AddCollectionBox: "சேகரிப்பைச் சேர்க்கவும்",
  DonationBox_EditCollectionBox: "சேகரிப்பைத் திருத்து",
  created_by: "உருவாக்கியவர்",
  edit_collection: "சேகரிப்பைத் திருத்து",
  enter_price_manually: "தொகையை உள்ளிடவும்",
  viewLogs: "பதிவுகளைப் பார்க்கவும்",
  logs: "பதிவுகள்",
  add_remarks: "குறிப்புகளைச் சேர்க்கவும்",
  add_amount: "தொகையைச் சேர்க்கவும்",
  DonationBox_AddDonationBox: "தானப்பெட்டியைச் சேர்க்கவும்",
  donation_box_desc_required: "விளக்கம் தேவை",
  donation_box_amount_required: "தொகை தேவை",

  logData_editedBy: "திருத்தியவர்",
  logData_createdBy: "உருவாக்கியவர்",
  logData_timeDate: "நேரம் & தேதி",
  logData_createdAmount: "உருவாக்கிய தொகை",
  logData_editedAmount: "திருத்திய தொகை",

  donation_box_not_found: "தானப்பெட்டிகள் காணவில்லை",
  donation_box_not_click_add_donation_box:
    "புதிய தானப்பெட்டியை அறிமுகப்படுத்த 'சேகரிப்பைச் சேர்க்கவும்' என்பதைக் கிளிக் செய்க.",

  // All Commitment Data
  notify_user: "பயனரை அறிவிக்கவும்",
  notify_user_content: "பயனரை மெல்ல அவிழ்க்கச் சோதனை பெட்டியைச் சரிபார்க்கவும்",
  commitment_Username: "பயனர் பெயர்",
  add_commitment: "உறுதியைச் சேர்க்கவும்",
  edit_commitment: "உறுதியைத் திருத்து",
  commitment_end_Date: "முடிவு தேதி",
  commitment_Amount_Due: "நிலுவை தொகை",
  commitment_select_commitment_id: "உறுதி ஐடியைத் தேர்ந்தெடுக்கவும்",
  commitment_select_start_date: "தொடக்க தேதியைத் தேர்ந்தெடுக்கவும்",
  commitment_select_end_date: "முடிவு தேதியைத் தேர்ந்தெடுக்கவும்",

  commitment_delete: "உறுதியை நீக்கு",
  commitment_sure: "இந்த உறுதியை நிரந்தரமாக நீக்க விரும்புகிறீர்களா?",
  commitment_not_found: "உறுதிகள் கிடைக்கவில்லை",
  commitment_not_click_add_commitment:
    "புதிய உறுதியைச் செய்ய 'உறுதியைச் சேர்க்கவும்' என்பதைக் கிளிக் செய்க.",
  commitment_complete: "நிறைவு",
  commitment_partial: "பகுதி",

  // All subscribed user static data
  subscribed_user_add_user: "சந்தா பயனர் சேர்க்கவும்",
  subscribed_user_name: "பயனர் பெயர்",
  subscribed_user_pan_cardDetail: "PAN அட்டை விவரங்கள்",
  subscribed_user_date_birth: "பிறந்த தேதி",
  subscribed_user_email: "மின்னஞ்சல்",
  subscribed_user_address: "முகவரி",
  user_name: "பயனர் பெயர்",
  subscribed_not_found: "சந்தா பயனர் கிடைக்கவில்லை",
  subscribed_not_click_add:
    'சந்தா பயனர் சேர்க்க "சந்தா பயனர் சேர்க்கவும்" என்பதைக் கிளிக் செய்க.',

  // All report & dispute static data
  report_report_against: "பெயர்",
  report_mobile: "மொபைல் எண்",
  export_report: "அறிக்கையை ஏற்றுமதி செய்க",
  report_Transaction_IDs: "பரிமாற்ற ஐடிக்கள்",
  report_panding: "நிலுவையில்",
  report_re_solved: "மீண்டும் தீர்க்கப்பட்டது",
  report_dispute_not_found: "அறிக்கைகள் மற்றும் முரண்பாடுகள் இல்லை",

  // All notification static data
  notifications: "அறிவிப்புகள்",
  notifications_Notify_Users: "பயனர்களை அறிவிக்கவும்",
  notifications_not_found: "அறிவிப்புகள் கிடைக்கவில்லை",

  // All user Profile static Data
  about_trust: "எங்களை பற்றி",
  trust_address: "முகவரி",
  trust_user: "பயனர் பற்றி",
  trust_location: "இடம்",
  trust_facility: "சேவைகள்",
  trust_facebookLink: "பேஸ்புக் இணைப்பு",
  trust_facebookLink_required: "பேஸ்புக் இணைப்பு தேவை",
  trust_contact_number_required: "தொடர்பு எண் தேவை.",
  contact_number: "தொடர்பு எண்",
  trust_image_certificate: "படங்கள் மற்றும் சான்றிதழ்கள்",
  location: "இடம்",
  trust_prefenses: "அறக்கட்டளை விருப்பங்கள்",
  trust_prefenses_required: "அறக்கட்டளை விருப்பங்கள் தேவை",
  latitude: "அட்சரேகை",
  longitude: "நெட்டரேகை",
  userProfile: "சுயவிவரம்",
  user_editUser: "பயனரைத் திருத்து",
  user_userRoleRequired: "பயனர் பங்கு தேவை",
  minimum_one_role_required:
    "குறைந்தபட்சம் ஒரு பயனர் பங்கு தேர்வு செய்யப்பட வேண்டும்",
  password_required: "கடவுச்சொல் தேவை",
  userProfile_phone_number: "தொலைபேசி எண்",
  trust_trustType: "அறக்கட்டளை வகை",
  about_trusts: "பற்றி",
  userProfile_name: "பெயர்",
  trust_type_required: "அறக்கட்டளை வகை தேவை",
  trust_about_required: "அறக்கட்டளை பற்றிய தகவல் தேவை",
  State: "மாநிலம்",
  City: "நகரம்",
  events_state_required: "மாநிலம் தேவை",
  events_city_required: "நகரம் தேவை",
  events_location_required: "இடம் தேவை",
  facilities: "சேவைகள்",
  userProfile_email_id: "மின்னஞ்சல்",
  userProfile_temple: "கோவில்",
  longitude_required: "நெட்டரேகை தேவை",
  latitude_required: "அட்சரேகை தேவை",
  user_password: "கடவுச்சொல்",
  user_password_old: "பழைய கடவுச்சொல்",
  user_password_new: "புதிய கடவுச்சொல்",
  edit_image: "படத்தைத் திருத்து",
  user_password_confirm: "கடவுச்சொல் உறுதிப்படுத்துக",
  userProfile_trust_type: "அறக்கட்டளை வகை",
  userProfile_doc_certificate: "ஆவணம்/சான்றிதழ்",
  userProfile_facilities: "சேவைகள்",
  userProfile_punyarjak: "புண்யார்ஜாக்",
  userProfile_temple_upload_doc: "உங்கள் கோவில் ஆவணத்தைப் பதிவேற்றுக",
  user_userRole: "பயனர் பங்குகள்",
  images: "படங்கள்",
  browse: "உலாவுக",
  update_profile: "சுயவிவரத்தைப் புதுப்பிக்கவும்",
  name_required: "பெயர் தேவை",
  start_time: "தொடக்க நேரம்",
  start_time_required: "தொடக்க நேரம் தேவை",
  end_time: "முடிவு நேரம்",
  end_time_required: "முடிவு நேரம் தேவை",

  email_invalid: "தவறான மின்னஞ்சல்",
  email_required: "மின்னஞ்சல் தேவை",
  number_type: "அது தொலைபேசி எண் போல தோன்றவில்லை",
  cant_start_minus: "தொலைபேசி எண் குறைவுடன் தொடங்க முடியாது",
  number_in_point: "தொலைபேசி எண்ணில் புள்ளி இருக்க முடியாது",
  number_required: "தொலைபேசி எண் தேவை",
  address_required: "முகவரி தேவை",
  Temple_name_required: "கோவில் பெயர் தேவை",
  doc_required: "கோவில் ஆவணம் தேவை",

  // punyarjak
  punyarjak: "புண்யார்ஜாக்",
  add_punyarjak: "புண்யார்ஜாக் சேர்க்கவும்",
  img_required: "படம் தேவை",
  edit_punyarjak: "புண்யார்ஜாக் திருத்து",
  name: "பெயர்",
  description: "விளக்கம்",
  punyarjak_desc_required: "விளக்கம் தேவை",
  punyarjak_delete: "புண்யார்ஜாக் நீக்கு",
  username: "பயனர் பெயர் எழுத்துகளை மட்டுமே கொண்டிருக்க வேண்டும்",
  punyarjak_sure: "இந்த புண்யார்ஜாக் நிரந்தரமாக நீக்க விரும்புகிறீர்களா?",
  punyarjak_not_found: "புண்யார்ஜாக் பயனர்கள் கிடைக்கவில்லை",
  punyarjak_not_click_add:
    "புதிய புண்யார்ஜாக் பயனரைச் சேர்க்க 'புண்யார்ஜாக் சேர்க்கவும்' என்பதைக் கிளிக் செய்க.",

  // all place holder images
  placeHolder_donar_name: "தான நபரின் பெயரை உள்ளிடுக",
  placeHolder_price_per_item: "ஒரு பொருளின் விலையை உள்ளிடுக",
  placeHolder_mobile_number: "மொபைல் எண்ணை உள்ளிடுக",
  placeHolder_phone_number: "தொடர்பு எண்ணை உள்ளிடுக",
  placeHolder_title: "தலைப்பை உள்ளிடுக",
  placeHolder_description: "விளக்கத்தை உள்ளிடுக",
  placeHolder_tags: "குறிச்சொற்களை உள்ளிடுக",
  placeHolder_name: "பெயரை உள்ளிடுக",
  placeHolder_user_name: "பயனர் பெயரை உள்ளிடுக",
  placeHolder_email: "மின்னஞ்சலை உள்ளிடுக",
  placeHolder_password: "கடவுச்சொல்லை உள்ளிடுக",
  placeHolder_sub_category: "உப வகையை உள்ளிடுக",
  placeHolder_city: "நகரத்தை உள்ளிடுக",
  placeHolder_state: "மாநிலத்தை உள்ளிடுக",
  placeHolder_location: "இடத்தை உள்ளிடுக",
  placeHolder_trust_facebook: "பேஸ்புக் இணைப்பை உள்ளிடுக",
  placeHolder_trust_address: "முகவரியை உள்ளிடுக",
  placeHolder_cattle_id: "பசுபொருள் ஐடியைத் தேர்ந்தெடுக்கவும்",
  placeHolder_calf_id: "கன்றின் ஐடியைத் தேர்ந்தெடுக்கவும்",
  placeHolder_cattle_treatment_medicine: "சிகிச்சை/மருந்தை உள்ளிடுக",
  placeHolder_cattle_dosage: "அளவை உள்ளிடுக",
  placeHolder_cattle_dr_name: "மருத்துவரின் பெயரை உள்ளிடுக",
  placeHolder_cattle_purchase_price: "விலையை உள்ளிடுக",
  placeHolder_cattle_fees: "மருத்துவ செலவை உள்ளிடுக",
  placeHolder_cattle_symptoms: "அறிகுறிகளை உள்ளிடுக",
  placeHolder_cattle_pregnancy_status: "கர்ப்ப நிலையை உள்ளிடுக",
  placeHolder_cattle_itemId: "பொருள் ஐடியைத் தேர்ந்தெடுக்கவும்",
  placeHolder_cattle_item_name: "பொருள் பெயரைத் தேர்ந்தெடுக்கவும்",
  placeHolder_cattle_expense_quantity: "அளவை உள்ளிடுக",
  placeHolder_cattle_unit: "அளவு அலகைத் தேர்ந்தெடுக்கவும்",
  placeHolder_cattle_expense_unitType: "அலகு வகையைத் தேர்ந்தெடுக்கவும்",
  placeHolder_cattle_purpose: "நோக்கத்தை உள்ளிடுக",
  placeHolder_cattle_item_id: "பொருள் பெயரை உள்ளிடுக",
  placeHolder_cattle_expense_current_quantity: "தற்போதைய அளவை உள்ளிடுக",
  placeHolder_cattle_expense_order_quantity: "ஆர்டர் அளவை உள்ளிடுக",
  placeholder_cattle_type: "பசுபொருள் வகையைத் தேர்ந்தெடுக்கவும்",
  placeHolder_cattle_breed: "பசுபொருள் இனப்பெருக்கத்தைத் தேர்ந்தெடுக்கவும்",
  placeHolder_cattle_age: "வயதை உள்ளிடுக",
  placeHolder_cattle_source: "மூலத்தைத் தேர்ந்தெடுக்கவும்",
  placeHolder_cattle_owner_name: "உரிமையாளர்/காவலரின் பெயரை உள்ளிடுக",
  placeHolder_cattle_owner_Id: "உரிமையாளர்/காவலரின் ஐடியை உள்ளிடுக",
  placeHolder_cattle_tag_id: "குறிச்சொல் ஐடியை உள்ளிடுக",
  placeholder_cattle_mother_id: "மாதா குறிச்சொல் ஐடியை உள்ளிடுக",
  placeHolder_cattle_owner_number: "உரிமையாளர்/காவலரின் எண்ணை உள்ளிடுக",
  placeHolder_cattle_death_reason: "இறப்பு காரணத்தை உள்ளிடுக",
  placeHolder_cattle_purchaser_name: "வாங்குநரின் பெயரை உள்ளிடுக",
  placeHolder_cattle_purchaser_number: "வாங்குநரின் எண்ணை உள்ளிடுக",
  placeHolder_cattle_purchaser_id: "வாங்குநரின் ஐடியை உள்ளிடுக",
  placeHolder_cattle_sold_price: "விற்கும் விலையை உள்ளிடுக",
  placeHolder_cattle_milk_quantity: "பாலின் அளவை லிட்டரில் உள்ளிடுக",
  placeHolder_expense_type: "செலவின் வகையைத் தேர்ந்தெடுக்கவும்",

  // All search static keys
  search_notification: "அனைத்து அறிவிப்புகளையும் தேடுக",
  search_subscribe_user: "அனைத்து சந்தா பயனர்களையும் தேடுக",
  search_news: "அனைத்து செய்திகளையும் தேடுக",
  search_events: "அனைத்து நிகழ்வுகளையும் தேடுக",
  search_notices: "அனைத்து அறிவிப்புகளையும் தேடுக",
  search_categories: "அனைத்து வகைகளையும் தேடுக",
  search_users: "அனைத்து பயனர்களையும் தேடுக",
  search_disputes: "அனைத்து முரண்பாடுகளையும் தேடுக",
  search_expenses: "அனைத்து செலவுகளையும் தேடுக",
  search_financial_reports: "அனைத்து நிதி அறிக்கைகளையும் தேடுக",
  search_donation: "அனைத்து தானங்களையும் தேடுக",
  search_punyarjak: "அனைத்து புண்யார்ஜாக் பயனர்களையும் தேடுக",
  search_donation_box_collection: "அனைத்து தானப்பெட்டி சேகரிப்புகளையும் தேடுக",
  search_commitment: "அனைத்து உறுதிகளையும் தேடுக",
};
