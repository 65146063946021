export const marathiContent = {
  no_data_found: "डेटा आढळला नाही",
  no_data_found_add_data: 'स्क्रीनवर डेटा जोडण्यासाठी "जोडा बटण" क्लिक करा',
  amount: "रक्कम",
  all: "सर्व",
  dashboard: "डॅशबोर्ड",
  donation: "दान",
  commitment: "प्रतिज्ञा",
  hundi: "दानपेटी संकलन",
  financial_reports: "आर्थिक अहवाल",
  internal_expenses: "खर्च",
  total_donation: "एकूण दान:",
  events: "कार्यक्रम",
  total: "एकूण:",
  Select: "निवडा",
  news: "बातम्या",
  notices: "सूचना",
  configuration: "संरचना",
  searchBarPlaceHolder: "मंदिरे, ट्रस्ट, इव्हेंट्स, बातम्या शोधा",
  monthName_January: "जानेवारी",
  monthName_February: "फेब्रुवारी",
  monthName_March: "मार्च",
  monthName_April: "एप्रिल",
  monthName_May: "मे",
  monthName_June: "जून",
  monthName_July: "जुलै",
  monthName_August: "ऑगस्ट",
  monthName_September: "सप्टेंबर",
  monthName_October: "ऑक्टोबर",
  monthName_November: "नोव्हेंबर",
  monthName_December: "डिसेंबर",
  last_login: "शेवटचा लॉगिन",
  category: "श्रेणी",
  user: "सहायक प्रशासक",
  report_Dispute: "अहवाल आणि वाद",
  cancel: "रद्द करा",
  confirm: "डिलीटची पुष्टी करा",
  save_changes: "बदल जतन करा",
  rupees: "₹",
  downloadReceipt: "रसिद डाउनलोड करा",
  pay_donation: "दान द्या",
  payment: "आता द्या",
  paymentPaid: "चुकवलेले",
  injection_found: "विशेष वर्ण समर्थित नाहीत",
  logout_msg: "तुम्हाला खात्री आहे का की तुम्ही लॉगआउट करु इच्छिता?",
  no: "नाही",
  yes: "होय",
  tags_limit: "टॅग्स मर्यादा ओलांडली आहे",
  end_time_less: "अंतिम वेळ प्रारंभ वेळेपेक्षा अधिक असावा",
  publish_now: "आता प्रकाशित करा",
  already_publish: "बातमी आधीच प्रकाशित केली आहे.",
  already_publish_notice: "सूचना आधीच प्रकाशित केली आहे.",
  unPublish: "अप्रकाशित करा",
  schedule: "वेळापत्रक",
  reSchedule: "पुन्हा वेळापत्रक",
  publish: "प्रकाशित करा",
  published: "प्रकाशित झाले",
  invalid_amount: "अवैध रक्कम",
  tagsChar_limit:
    "टॅग क्षेत्राची वर्ण मर्यादा 20 आहे आणि त्याहून अधिक स्वीकारली जाऊ शकत नाही.",
  // All static content of Dashboard
  dashboard_donationReceived: "प्राप्त दान",
  dashboard_donationPending: "प्रलंबित दान",
  dashboard_all: "सर्व",
  donation_hundi: "दान + हुंडी",
  dashboard_totalExpenses: "एकूण खर्च",
  noTopDonar: "कोणतेही टॉप दाता नाहीत",
  dashboard_RevenueReport: "आर्थिक अहवाल",
  notification_not_found: "कोणतीही सूचना सापडली नाही",
  dashboard_monthly: "या महिन्यात",
  dashboard_weekly: "या आठवड्यात",
  dashboard_yearly: "या वर्षात",
  dashboard_daily: "दैनिक",

  dashboard_card_title3: "सदस्यता घेतलेले वापरकर्ते",
  dashboard_Recent_DonationCommitment: "अलीकडील दान",
  dashboard_Recent_DonorName: "दाता नाव",
  dashboard_Recent_DonorNumber: "मोबाईल नंबर",
  dashboard_Recent_DonorType: "प्रकार",
  dashboard_Recent_DonorDate: "तारीख आणि वेळ",
  dashboard_Recent_DonorStatus: "स्थिती",
  dashboard_Recent_DonorAmount: "रक्कम",
  dashboard_Recent_DonorCommitId: "प्रतिज्ञा ID",
  dashboard_Recent_DonorReceipt: "रसिद",
  dashboard_viewAll: "सर्व पहा",
  dashboard_top: "टॉप 5 दाते",

  // All static content of News

  news_AddNews: "बातमी जोडा",
  news_EditNews: "बातमी संपादन करा",
  news_AddLangNews: "भाषा जोडा",
  news_InputIn: "भाषा निवडा:",
  news_label_Title: "शीर्षक",
  news_label_Tags: "टॅग्स",
  news_label_Description: "वर्णन जोडा",
  news_label_Date: "निवडलेली तारीख",
  news_label_Published: "प्रकाशित द्वारे",
  news_label_ImageVedio: "छायाचित्रे जोडा",
  news_button_Publish: "प्रकाशित करा",
  news_title_required: "शीर्षक आवश्यक आहे",
  news_tags_required: "टॅग्स आवश्यक आहेत",
  news_desc_required: "वर्णन आवश्यक आहे",
  news_publish_required: "प्रकाशकाचे नाव आवश्यक आहे",
  news_latest_news: "बातम्या",
  news_btn_AddNews: "बातमी जोडा",
  news_popOver_AddLang: "भाषा जोडा",
  news_popOver_Edit: "संपादन करा",
  news_popOver_Delete: "काढून टाका",

  news_delete: "बातमी डिलीट करा",
  news_sure:
    "तुम्हाला खात्री आहे का की तुम्ही ही बातमी कायमची काढून टाकू इच्छिता?",
  news_not_found: "बातमी अनुपलब्ध",
  news_not_click_add_news: "नवीन बातमी लेख जोडण्यासाठी 'बातमी जोडा' क्लिक करा.",

  // All static content of Events
  same_time: "अंत वेळ प्रारंभ वेळेसारखी असू शकत नाही",
  events_AddEvent: "कार्यक्रम जोडा",
  events_latest_event: "नवीनतम कार्यक्रम",
  events_title_required: "शीर्षक आवश्यक आहे",
  events_desc_required: "वर्णन आवश्यक आहे",
  events_select_dropDown: "कार्यक्रम निवडा",
  events_select_globle: "ग्लोबल इव्हेंट निवडा",
  events_EditEvent: "कार्यक्रम संपादन करा",
  events_startTime_required: "प्रारंभ वेळ आवश्यक आहे",
  events_endTime_required: "अंत वेळ आवश्यक आहे",
  events_startDate_required: "प्रारंभ तारीख आवश्यक आहे",
  events_endDate_required: "अंत तारीख आवश्यक आहे",
  events_delete: "कार्यक्रम काढून टाका",
  events_sure:
    "तुम्हाला खात्री आहे का की तुम्ही हा कार्यक्रम कायमचा काढून टाकू इच्छिता?",
  events_not_found: "कार्यक्रम अनुपलब्ध",
  events_not_click_add_events:
    "नवीन कार्यक्रम जोडण्यासाठी 'कार्यक्रम जोडा' क्लिक करा",

  // All static content of Pashudhan
  cattles_management: "गौशाला",
  cattle_expense_type_assets: "मालमत्ता",
  cattle_dead: "मृत",
  cattle_alive: "जिवंत",
  cattle_expense_type_consumable: "वापरण्यायोग्य",
  cattle_expense_type_general: "सामान्य",
  cattle_info: "पशुधन माहिती",
  cattle_photo: "पशुधनाचा फोटो",
  cattle_owner_photo: "मालकाचा फोटो",
  cattle_owner_id: "मालकाचा आयडी",
  cattle_mother_id: "माताचा टॅग आयडी",
  cattle_breed: "वंश",
  cattle_date_of_birth: "जन्म तारीख",
  cattle_age: "वय",
  cattle_is_pregnant: "गर्भवती आहे",
  cattle_is_milking: "दूध देते",
  cattle_milk_quantity: "दूधाची मात्रा",
  cattle_fees: "वैद्यकीय खर्च",
  cattle_medical_record: "वैद्यकीय नोंदी",
  cattle_pregnancy_record: "गर्भावस्था नोंदी",
  cattle_expense: "खर्च व्यवस्थापन",
  cattle_stock: "साठा व्यवस्थापन",
  cattle_usage: "वापर व्यवस्थापन",
  cattle_revenueHeading: "दैनिक नोंदणी अहवाल",
  recent_registered_cattles: "नवीन नोंदणीकृत पशुधन",
  cattle_registered: "नोंदणीकृत पशुधन",
  cattle_add: "पशुधन जोडा",
  cattle_record_add: "नोंद जोडा",
  cattle_breed_add: "वंश जोडा",
  cattle_breed_edit: "वंश संपादन करा",
  cattle_medical_add: "वैद्यकीय नोंद जोडा",
  cattle_pregnancy_report: "गर्भावस्था नोंदी",
  cattle_pregnancy_report_add: "गर्भावस्था नोंद जोडा",
  cattle_expense_add: "खर्च जोडा",
  cattle_supplies_add: "पुरवठा जोडा",
  cattle_items_add: "वस्तू जोडा",
  cattle_id: "पशुधन आयडी",
  calf_id: "वासरू आयडी",
  cattle_treatment_medicine: "उपचार/औषध",
  cattle_dosage: "डोस",
  cattle_dr_name: "डॉ.चे नाव",
  cattle_purchase_price: "खरेदी किंमत",
  cattle_symptoms: "लक्षणे",
  cattle_usage_add: "वापर जोडा",
  cattles: "पशुधन",
  cattles_feed: "चारापाणी",
  cattles_medical: "वैद्यकीय",
  cattles_death: "मृत्यू",
  cattles_machinery: "यंत्रसामग्री",
  cattle_conceiving_date: "गर्भधारण तारीख",
  cattle_pregnancy_date: "गर्भावस्था तारीख",
  cattle_delivery_date: "वितरण तारीख",
  cattle_pregnancy_status: "गर्भावस्था स्थिती",
  cattle_purpose: "उद्देश",
  cattle_itemId: "वस्तू आयडी",
  cattle_itemName: "वस्तूचे नाव",
  cattle_expense_quantity: "मात्रा",
  cattle_expense_bill_invoice: "बिल/चलन",
  cattle_expense_bill_invoice_upload: "अपलोड",
  cattle_unit: "युनिट",
  cattle_expense_unitType: "युनिट प्रकार",
  cattle_expense_current_quantity: "वर्तमान मात्रा",
  cattle_expense_order_quantity: "ऑर्डर मात्रा",
  cattle_last_update: "शेवटचे अद्यतन",
  cattle_tag_id: "टॅग आयडी",
  cattle_date_purchased: "खरेदी तारीख",
  cattle_source: "स्रोत",
  cattle_owner_name: "मालकाचे नाव",
  cattle_owner_number: "मालकाचा नंबर",
  cattle_owner_image: "मालकाचा फोटो",
  cattle_cow_image: "गायचा फोटो",
  cattle_is_dead: "मृत आहे",
  cattle_date_death: "मृत्यू तारीख",
  cattle_death_reason: "मृत्यूचे कारण",
  cattle_is_sold: "विकले गेले आहे",
  cattle_purchaser_name: "खरेदीदाराचे नाव",
  cattle_purchaser_number: "खरेदीदाराचा नंबर",
  cattle_purchaser_id: "खरेदीदाराचा आयडी",
  cattle_sold_date: "विक्री तारीख",
  cattle_sold_price: "विक्री किंमत",
  cattle_edit_medical: "वैद्यकीय नोंद संपादन करा",
  cattle_edit_pregnancy: "गर्भावस्था नोंद संपादन करा",
  cattle_edit_cattle: "पशुधन संपादन करा",
  cattle_edit_item: "वस्तू संपादन करा",
  cattle_edit_supplies: "पुरवठा संपादन करा",
  cattle_edit_usage: "वापर संपादन करा",
  cattle_category_edit: "श्रेणी संपादन करा",
  cattle_category_add: "श्रेणी जोडा",

  // cattle validation messages
  cattle_itemID_required: "वस्तू आयडी आवश्यक आहे",
  cattle_id_required: "पशुधन आयडी आवश्यक आहे",
  cattle_pregnancy_status_required: "गर्भावस्था स्थिती आवश्यक आहे",
  cattle_name_required: "नाव आवश्यक आहे",
  cattle_order_quantity_required: "ऑर्डर मात्रा आवश्यक आहे",
  cattle_unit_required: "युनिट आवश्यक आहे",
  cattle_unit_type_required: "युनिट प्रकार आवश्यक आहे",
  cattle_quantity_required: "मात्रा आवश्यक आहे",
  cattle_purpose_required: "उद्देश आवश्यक आहे",
  cattle_treatment_medicine_required: "उपचार/औषध आवश्यक आहे",
  cattle_dosage_required: "डोस आवश्यक आहे",
  cattle_DrName_required: "डॉ.चे नाव आवश्यक आहे",
  cattle_price_required: "वैद्यकीय खर्च आवश्यक आहे",
  cattle_symptoms_required: "लक्षणे आवश्यक आहेत",
  cattle_tag_id_required: "टॅग आयडी आवश्यक आहे",
  cattle_type_required: "श्रेणी आवश्यक आहे",
  cattle_breed_required: "वंश आवश्यक आहे",
  cattle_age_required: "वय आवश्यक आहे",
  cattle_purchase_price_required: "खरेदी किंमत आवश्यक आहे",
  cattle_source_required: "स्रोत आवश्यक आहे",
  cattle_owner_name_required: "मालकाचे नाव आवश्यक आहे",
  cattle_owner_id_required: "मालकाचा आयडी आवश्यक आहे",
  cattle_deathReason_required: "मृत्यूचे कारण आवश्यक आहे",
  cattle_purchaser_name_required: "खरेदीदाराचे नाव आवश्यक आहे",
  cattle_purchaser_id_required: "खरेदीदाराचा आयडी आवश्यक आहे",
  cattle_sold_price_required: "विक्री किंमत आवश्यक आहे",
  cattle_milk_quantity_required: "दूधाची मात्रा आवश्यक आहे",

  // all cattle management delete messages
  cattle_cattle_delete: "पशुधन काढून टाका",
  cattle_cattle_sure:
    "तुम्हाला खात्री आहे का की तुम्ही हे पशुधन काढून टाकू इच्छिता?",
  cattle_medical_delete: "वैद्यकीय माहिती काढून टाका",
  cattle_medical_sure:
    "तुम्हाला खात्री आहे का की तुम्ही हा वैद्यकीय अहवाल काढून टाकू इच्छिता?",
  cattle_pregnancy_delete: "गर्भावस्था अहवाल काढून टाका",
  cattle_pregnancy_sure:
    "तुम्हाला खात्री आहे का की तुम्ही हा गर्भावस्था अहवाल काढून टाकू इच्छिता?",
  cattle_supplies_delete: "पुरवठा काढून टाका",
  cattle_supplies_sure:
    "तुम्हाला खात्री आहे का की तुम्ही हा पुरवठा काढून टाकू इच्छिता?",

  cattle_usage_delete: "वापर काढून टाका",
  cattle_usage_sure:
    "तुम्हाला खात्री आहे का की तुम्ही हा वापर काढून टाकू इच्छिता?",

  cattle_item_delete: "वस्तू काढून टाका",
  cattle_item_sure:
    "तुम्हाला खात्री आहे का की तुम्ही ही वस्तू काढून टाकू इच्छिता?",
  cattle_breed_delete: "वंश काढून टाका",
  cattle_breed_sure:
    "तुम्हाला खात्री आहे का की तुम्ही हा वंश काढून टाकू इच्छिता?",

  // All cattle search variable
  search_cattles: "पशुधन आयडी, प्रकार, वंश द्वारे शोधा",
  search_cattles_medical: "पशुधन वैद्यकीय माहिती शोधा",
  search_cattles_pregnancy: "पशुधन गर्भावस्था अहवाल शोधा",
  search_cattles_stock: "साठा शोधा",
  search_cattles_supplies: "वस्तू पुरवठा शोधा",
  search_cattles_usage: "वस्तू वापर शोधा",
  search_cattles_items: "पशुधन वस्तू शोधा",
  // All static content of Notices

  notices_AddNotice: "सूचना जोडा",
  notices_latest_Notice: "सूचना",
  add_image: "छायाचित्र जोडा",
  image_size_suggestion: "(कृपया 450px*350px आकाराचे छायाचित्र अपलोड करा)",
  notices_title_required: "शीर्षक आवश्यक आहे",
  notices_desc_required: "वर्णन आवश्यक आहे",
  notices_select_dropDown: "सूचना निवडा",
  notices_EditNotice: "सूचना संपादन करा",

  notices_delete: "सूचना काढून टाका",
  notices_sure:
    "तुम्हाला खात्री आहे का की तुम्ही ही सूचना कायमची काढून टाकू इच्छिता?",
  notices_not_found: "सूचना अनुपलब्ध",
  notices_not_click_add_notices:
    "नवीन सूचना समाविष्ट करण्यासाठी, 'सूचना जोडा' क्लिक करा.",
  // All static content of Categories
  only_letters: "उप श्रेणी फक्त अक्षरे असावी",
  categories_AddCategory: "उप-श्रेणी जोडा",
  categories_serial_number: "अनुक्रमांक",
  categories_master_category: "मुख्य श्रेणी",
  categories_sub_category: "उप श्रेणी",
  categories_category_required: "मुख्य श्रेणी आवश्यक आहे",
  categories_sub_category_required: "उप श्रेणी आवश्यक आहे",
  categories_latest_Category: "श्रेण्या",
  categories_title_required: "श्रेणीचे नाव आवश्यक आहे",
  categories_EditCategory: "श्रेणी संपादन करा",
  categories_select_category: "श्रेणी निवडा",
  categories_select_amount: "रक्कम प्रविष्ट करा",
  category_select_sub_category: "उप श्रेणी निवडा",
  categories_select_user_name: "वापरकर्तानाव निवडा",
  category_select: "निवडा",
  categories_select_master_category: "मुख्य श्रेणी निवडा",
  donar_name_required: "दाता नाव आवश्यक आहे",
  created_by_required: "द्वारे तयार केलेले आवश्यक आहे",
  user_select_required: "वापरकर्तानाव आवश्यक आहे",
  masterCategory_required: "श्रेणी आवश्यक आहे",
  subCategory_required: "कृपया उप श्रेणी निवडा",
  commitmentID_required: "कृपया प्रतिबद्धता आयडी निवडा",
  amount_required: "रक्कम आवश्यक आहे",

  category_delete: "श्रेणी हटवा",
  category_sure:
    "आपल्याला खात्री आहे का की आपण या श्रेणीला कायमचे हटवू इच्छिता?",
  category_not_found: "श्रेण्या अनुपलब्ध",
  category_not_click_add_category:
    "नवीन श्रेणी जोडण्यासाठी, 'श्रेणी जोडा' क्लिक करा",

  // All static content of Users
  users_AddUser: "उप प्रशासक जोडा",
  users_latest_User: "उप प्रशासक",
  users_title_required: "वापरकर्तानाव आवश्यक आहे",
  user_only_letters: "वापरकर्तानाव फक्त अक्षरे असावी",
  users_mobile_required: "मोबाइल नंबर आवश्यक आहे",
  mobile_number_limit: "मोबाइल नंबर 10 अंकांचा असावा",
  users_email_required: "ईमेल आवश्यक आहे",
  users_not_found: "उप प्रशासक सापडले नाहीत",
  users_not_click_add_users:
    "नवीन उप प्रशासक वापरकर्ता जोडण्यासाठी 'उप प्रशासक जोडा' क्लिक करा",

  // All static content of Internal Expense
  expenses_AddExpenses: "खर्च जोडा",
  price_per_item: "प्रति वस्तू किंमत",
  expense_type_required: "खर्च प्रकार आवश्यक आहे",
  expenses_EditExpenses: "खर्च संपादन करा",
  expenses_Date: "तारीख",
  expence_description: "वर्णन",
  expenses_latest_Expenses: "सर्व खर्च",
  expenses_title_required: "शीर्षक आवश्यक आहे",
  expenses_mobile_required: "मोबाइल नंबर आवश्यक आहे",
  expenses_email_required: "ईमेल आवश्यक आहे",
  added_by: "द्वारे जोडलेले",
  expenses_desc_required: "वर्णन आवश्यक आहे",
  expence_amount_required: "रक्कम आवश्यक आहे",

  expence_delete: "खर्च हटवा",
  expence_sure: "आपल्याला खात्री आहे का की आपण या खर्चाला कायमचे हटवू इच्छिता?",
  expence_not_found: "खर्च सापडला नाही",
  expence_not_click_add_expence:
    "नवीन खर्च नोंदवण्यासाठी, 'खर्च जोडा' क्लिक करा",

  // All static content of Financial Report

  report_AddReport: "आर्थिक अहवाल",
  report_expences: "खर्च",
  remarks_financial_donationBox: "टीप",
  report_commitment: "प्रतिबद्धता",
  reports_not_found: "अहवाल आणि वाद सापडला नाही",
  report_donation_box: "दानपेटी संकलन",
  finance_report_no_found:
    "निवडलेल्या कालावधीसाठी कोणताही आर्थिक अहवाल उपलब्ध नाही",
  financial_not_found: "कोणतेही आर्थिक मिळाले नाही",

  // All static content of Donation
  donation_Donation: "दान",
  start_Time: "प्रारंभ वेळ",
  end_Time: "अंत वेळ",
  is_government: "सरकारी दाता आहे",
  donation_Adddonation: "दान जोडा",
  original_amount: "मूळ रक्कम",
  estimate_amount: "अंदाजित रक्कम",
  donation_select_date_time: "तारीख निवडा",
  donation_select_date: "तारीख निवडा",
  donation_donar_name_only_letters: "दाता नाव फक्त अक्षरे असावे",
  add_user_donation: "कोणताही वापरकर्ता सापडला नाही, येथे क्लिक करा",
  donation_delete: "दान हटवा",
  donation_sure: "आपल्याला खात्री आहे का की आपण या दानाला कायमचे हटवू इच्छिता?",
  donation_not_found: "कोणतेही दान सापडले नाही",
  add_user: "वापरकर्ता जोडा",
  donation_paid_not_found: "कोणतेही चुकवलेले दान सापडले नाही",
  donation_not_click_add_donation:
    "स्क्रीनवर दान जोडण्यासाठी 'दान जोडा' क्लिक करा",

  // All static content of Donation Box
  DonationBox_total_collection: "एकूण संकलन:",
  DonationBox_DonationBox: "दानपेटी संकलन",
  DonationBox_AddCollectionBox: "संकलन जोडा",
  DonationBox_EditCollectionBox: "संकलन संपादन करा",
  created_by: "द्वारे तयार केलेले",
  edit_collection: "संकलन संपादन करा",
  enter_price_manually: "रक्कम प्रविष्ट करा",
  viewLogs: "लॉग्स पहा",
  logs: "लॉग्स",
  add_remarks: "टीप जोडा",
  add_amount: "रक्कम जोडा",
  DonationBox_AddDonationBox: "दानपेटी जोडा",
  donation_box_desc_required: "वर्णन आवश्यक आहे",
  donation_box_amount_required: "रक्कम आवश्यक आहे",

  logData_editedBy: "संपादित केलेले",
  logData_createdBy: "द्वारे तयार केलेले",
  logData_timeDate: "वेळ आणि तारीख",
  logData_createdAmount: "तयार केलेली रक्कम",
  logData_editedAmount: "संपादित केलेली रक्कम",

  donation_box_not_found: "दानपेट्या अनुपलब्ध",
  donation_box_not_click_add_donation_box:
    "नवीन दानपेटी जोडण्यासाठी, 'संकलन जोडा' क्लिक करा",

  // All Commitment Data
  notify_user: "वापरकर्त्याला सूचित करा",
  notify_user_content: "वापरकर्त्याला सूचना देण्यासाठी कृपया चेक बॉक्स तपासा",
  commitment_Username: "वापरकर्तानाव",
  add_commitment: "प्रतिबद्धता जोडा",
  edit_commitment: "प्रतिबद्धता संपादन करा",
  commitment_end_Date: "अंत तारीख",
  commitment_Amount_Due: "बाकी रक्कम",
  commitment_select_commitment_id: "प्रतिबद्धता आयडी निवडा",
  commitment_select_start_date: "प्रारंभ तारीख निवडा",
  commitment_select_end_date: "अंत तारीख निवडा",

  commitment_delete: "प्रतिबद्धता हटवा",
  commitment_sure:
    "आपल्याला खात्री आहे का की आपण या प्रतिबद्धतेला कायमचे हटवू इच्छिता?",
  commitment_not_found: "प्रतिबद्धता सापडली नाही",
  commitment_not_click_add_commitment:
    "नवीन प्रतिबद्धता करण्यासाठी, 'प्रतिबद्धता जोडा' क्लिक करा",
  commitment_complete: "पूर्ण झाले",
  commitment_partial: "आंशिक",

  // All subscribed user static data
  subscribed_user_add_user: "सदस्यता घेतलेला वापरकर्ता जोडा",
  subscribed_user_name: "वापरकर्तानाव",
  subscribed_user_pan_cardDetail: "PAN कार्ड तपशील",
  subscribed_user_date_birth: "जन्म तारीख",
  subscribed_user_email: "ईमेल",
  subscribed_user_address: "पत्ता",
  user_name: "वापरकर्तानाव",
  subscribed_not_found: "सदस्यता घेतलेला वापरकर्ता सापडला नाही",
  subscribed_not_click_add:
    "स्क्रीनवर सदस्यता घेतलेला वापरकर्ता जोडण्यासाठी 'सदस्यता घेतलेला वापरकर्ता जोडा' क्लिक करा",

  // All report & dispute static data
  report_report_against: "नाव",
  report_mobile: "मोबाइल नंबर",
  export_report: "अहवाल निर्यात करा",
  report_Transaction_IDs: "व्यवहार आयडी",
  report_panding: "प्रलंबित",
  report_re_solved: "पुन्हा सोडवलेले",
  report_dispute_not_found: "अहवाल आणि वाद सापडले नाहीत",

  // All notification static data
  notifications: "सूचना",
  notifications_Notify_Users: "वापरकर्त्यांना सूचित करा",
  notifications_not_found: "कोणतीही सूचना सापडली नाही",

  // All user Profile static Data
  about_trust: "आमच्याबद्दल",
  trust_address: "पत्ता",
  trust_user: "वापरकर्त्याबद्दल",
  trust_location: "स्थान",
  trust_facility: "सुविधा",
  trust_facebookLink: "फेसबुक लिंक",
  trust_facebookLink_required: "फेसबुक लिंक आवश्यक आहे",
  trust_contact_number_required: "संपर्क नंबर आवश्यक आहे",
  contact_number: "संपर्क नंबर",
  trust_image_certificate: "छायाचित्रे आणि प्रमाणपत्रे",
  location: "स्थान",
  trust_prefenses: "ट्रस्ट प्राधान्ये",
  trust_prefenses_required: "ट्रस्ट प्राधान्ये आवश्यक आहेत",
  latitude: "अक्षांश",
  longitude: "रेखांश",
  userProfile: "प्रोफाइल",
  user_editUser: "वापरकर्ता संपादन करा",
  user_userRoleRequired: "वापरकर्ता भूमिका आवश्यक आहे",
  minimum_one_role_required: "किमान एक वापरकर्ता भूमिका निवडावी लागेल",
  password_required: "पासवर्ड आवश्यक आहे",
  userProfile_phone_number: "फोन नंबर",
  trust_trustType: "ट्रस्ट प्रकार",
  about_trusts: "बद्दल",
  userProfile_name: "नाव",
  trust_type_required: "ट्रस्ट प्रकार आवश्यक आहे",
  trust_about_required: "ट्रस्टबद्दल माहिती आवश्यक आहे",
  State: "राज्य",
  City: "शहर",
  events_state_required: "राज्य आवश्यक आहे",
  events_city_required: "शहर आवश्यक आहे",
  events_location_required: "स्थान आवश्यक आहे",
  facilities: "सुविधा",
  userProfile_email_id: "ईमेल",
  userProfile_temple: "मंदिर",
  longitude_required: "रेखांश आवश्यक आहे",
  latitude_required: "अक्षांश आवश्यक आहे",
  user_password: "पासवर्ड",
  user_password_old: "जुना पासवर्ड",
  user_password_new: "नवा पासवर्ड",
  edit_image: "छायाचित्र संपादन करा",
  user_password_confirm: "पासवर्ड पुष्टी करा",
  userProfile_trust_type: "ट्रस्ट प्रकार",
  userProfile_doc_certificate: "दस्तावेज/प्रमाणपत्र",
  userProfile_facilities: "सुविधा",
  userProfile_punyarjak: "पुण्यर्जक",
  userProfile_temple_upload_doc: "तुमचे मंदिर दस्तावेज अपलोड करा",
  user_userRole: "वापरकर्ता भूमिका",
  images: "छायाचित्रे",
  browse: "ब्राउझ करा",
  update_profile: "प्रोफाइल अपडेट करा",
  name_required: "नाव आवश्यक आहे",
  start_time: "प्रारंभ वेळ",
  start_time_required: "प्रारंभ वेळ आवश्यक आहे",
  end_time: "अंत वेळ",
  end_time_required: "अंत वेळ आवश्यक आहे",

  email_invalid: "अवैध ईमेल",
  email_required: "ईमेल आवश्यक आहे",
  number_type: "तो फोन नंबर वाटत नाही",
  cant_start_minus: "फोन नंबर माइनसने सुरू होऊ शकत नाही",
  number_in_point: "फोन नंबरमध्ये दशांश बिंदू असू शकत नाही",
  number_required: "फोन नंबर आवश्यक आहे",
  address_required: "पत्ता आवश्यक आहे",
  Temple_name_required: "मंदिराचे नाव आवश्यक आहे",
  doc_required: "मंदिराचा दस्तावेज आवश्यक आहे",

  // punyarjak

  punyarjak: "पुण्यर्जक",
  add_punyarjak: "पुण्यर्जक जोडा",
  img_required: "छायाचित्र आवश्यक आहे",
  edit_punyarjak: "पुण्यर्जक संपादन करा",
  name: "नाव",
  description: "वर्णन",
  punyarjak_desc_required: "वर्णन आवश्यक आहे",
  punyarjak_delete: "पुण्यर्जक हटवा",
  username: "वापरकर्तानाव फक्त अक्षरे असावी",
  punyarjak_sure:
    "आपल्याला खात्री आहे का की आपण या पुण्यर्जकला कायमचे हटवू इच्छिता?",
  punyarjak_not_found: "पुण्यर्जक वापरकर्ते सापडले नाहीत",
  punyarjak_not_click_add:
    "नवीन पुण्यर्जक वापरकर्ता जोडण्यासाठी, 'पुण्यर्जक जोडा' क्लिक करा",

  // all place holder images

  placeHolder_donar_name: "दाता नाव प्रविष्ट करा",
  placeHolder_price_per_item: "प्रति वस्तू किंमत प्रविष्ट करा",
  placeHolder_mobile_number: "मोबाइल नंबर प्रविष्ट करा",
  placeHolder_phone_number: "संपर्क नंबर प्रविष्ट करा",
  placeHolder_title: "शीर्षक प्रविष्ट करा",
  placeHolder_description: "वर्णन प्रविष्ट करा",
  placeHolder_tags: "टॅग्स प्रविष्ट करा",
  placeHolder_name: "नाव प्रविष्ट करा",
  placeHolder_user_name: "वापरकर्तानाव प्रविष्ट करा",
  placeHolder_email: "ईमेल प्रविष्ट करा",
  placeHolder_password: "पासवर्ड प्रविष्ट करा",
  placeHolder_sub_category: "उप श्रेणी प्रविष्ट करा",
  placeHolder_city: "शहर प्रविष्ट करा",
  placeHolder_state: "राज्य प्रविष्ट करा",
  placeHolder_location: "स्थान प्रविष्ट करा",
  placeHolder_trust_facebook: "फेसबुक लिंक प्रविष्ट करा",
  placeHolder_trust_address: "पत्ता प्रविष्ट करा",
  placeHolder_cattle_id: "पशुधन आयडी निवडा",
  placeHolder_calf_id: "वासरू आयडी निवडा",
  placeHolder_cattle_treatment_medicine: "उपचार/औषध प्रविष्ट करा",
  placeHolder_cattle_dosage: "डोस प्रविष्ट करा",
  placeHolder_cattle_dr_name: "डॉ.चे नाव प्रविष्ट करा",
  placeHolder_cattle_purchase_price: "किंमत प्रविष्ट करा",
  placeHolder_cattle_fees: "वैद्यकीय खर्च प्रविष्ट करा",
  placeHolder_cattle_symptoms: "लक्षणे प्रविष्ट करा",
  placeHolder_cattle_pregnancy_status: "गर्भावस्था स्थिती प्रविष्ट करा",
  placeHolder_cattle_itemId: "वस्तू आयडी निवडा",
  placeHolder_cattle_item_name: "वस्तूचे नाव निवडा",
  placeHolder_cattle_expense_quantity: "मात्रा प्रविष्ट करा",
  placeHolder_cattle_unit: "मात्रा युनिट निवडा",
  placeHolder_cattle_expense_unitType: "युनिट प्रकार निवडा",
  placeHolder_cattle_purpose: "उद्देश प्रविष्ट करा",
  placeHolder_cattle_item_id: "वस्तूचे नाव प्रविष्ट करा",
  placeHolder_cattle_expense_current_quantity: "वर्तमान मात्रा प्रविष्ट करा",
  placeHolder_cattle_expense_order_quantity: "ऑर्डर मात्रा प्रविष्ट करा",
  placeholder_cattle_type: "पशुधन श्रेणी निवडा",
  placeHolder_cattle_breed: "पशुधन वंश निवडा",
  placeHolder_cattle_age: "वय प्रविष्ट करा",
  placeHolder_cattle_source: "स्रोत निवडा",
  placeHolder_cattle_owner_name: "मालकाचे नाव प्रविष्ट करा",
  placeHolder_cattle_owner_Id: "मालकाचा आयडी प्रविष्ट करा",
  placeHolder_cattle_tag_id: "टॅग आयडी प्रविष्ट करा",
  placeholder_cattle_mother_id: "माताचा टॅग आयडी प्रविष्ट करा",
  placeHolder_cattle_owner_number: "मालकाचा नंबर प्रविष्ट करा",
  placeHolder_cattle_death_reason: "मृत्यूचे कारण प्रविष्ट करा",
  placeHolder_cattle_purchaser_name: "खरेदीदाराचे नाव प्रविष्ट करा",
  placeHolder_cattle_purchaser_number: "खरेदीदाराचा नंबर प्रविष्ट करा",
  placeHolder_cattle_purchaser_id: "खरेदीदाराचा आयडी प्रविष्ट करा",
  placeHolder_cattle_sold_price: "विक्री किंमत प्रविष्ट करा",
  placeHolder_cattle_milk_quantity: "लिटरमध्ये दूधाची मात्रा प्रविष्ट करा",
  placeHolder_expense_type: "खर्च प्रकार निवडा",

  // All search static keys
  search_notification: "सर्व सूचना शोधा",
  search_subscribe_user: "सर्व सदस्यता घेतलेले वापरकर्ते शोधा",
  search_news: "सर्व बातम्या शोधा",
  search_events: "सर्व कार्यक्रम शोधा",
  search_notices: "सर्व सूचना शोधा",
  search_categories: "सर्व श्रेण्या शोधा",
  search_users: "सर्व वापरकर्ते शोधा",
  search_disputes: "सर्व वाद शोधा",
  search_expenses: "सर्व खर्च शोधा",
  search_financial_reports: "सर्व आर्थिक अहवाल शोधा",
  search_donation: "सर्व दान शोधा",
  search_punyarjak: "सर्व पुण्यर्जक शोधा",
  search_donation_box_collection: "सर्व दानपेटी संकलन शोधा",
  search_commitment: "सर्व प्रतिबद्धता शोधा",
};
