export const gujaratiContent = {
  no_data_found: "કોઈ ડેટા મળ્યો નથી",
  no_data_found_add_data:
    'સ્ક્રીન પર ડેટા ઉમેરવા માટે "ઉમેરો બટન" પર ક્લિક કરો',
  amount: "રકમ",
  all: "બધા",
  dashboard: "ડેશબોર્ડ",
  donation: "દાન",
  commitment: "પ્રતિબદ્ધતા",
  hundi: "દાનપેટી સંકલન",
  financial_reports: "નાણાકીય અહેવાલ",
  internal_expenses: "આવક",
  total_donation: "કુલ દાન:",
  events: "પ્રવૃત્તિઓ",
  total: "કુલ:",
  Select: "પસંદ કરો",
  news: "સમાચાર",
  notices: "સૂચનાઓ",
  configuration: "ગોઠવણી",
  searchBarPlaceHolder: "મંદિરો, ટ્રસ્ટ, ઇવેન્ટ્સ, સમાચાર શોધો",
  monthName_January: "જાન્યુઆરી",
  monthName_February: "ફેબ્રુઆરી",
  monthName_March: "માર્ચ",
  monthName_April: "એપ્રિલ",
  monthName_May: "મે",
  monthName_June: "જૂન",
  monthName_July: "જુલાઈ",
  monthName_August: "ઑગસ્ટ",
  monthName_September: "સપ્ટેમ્બર",
  monthName_October: "ઑક્ટોબર",
  monthName_November: "નવેમ્બર",
  monthName_December: "ડિસેમ્બર",
  last_login: "છેલ્લા લૉગિન",
  category: "શ્રેણી",
  user: "સબ એડમિન",
  report_Dispute: "અહેવાલ અને વિવાદ",
  cancel: "રદ કરો",
  confirm: "કન્ફર્મ ડિલીટ",
  save_changes: "ફેરફારો સાચવો",
  rupees: "રૂ.",
  downloadReceipt: "રસીદ ડાઉનલોડ કરો",
  pay_donation: "દાન આપો",
  payment: "હવે ચૂકવણી કરો",
  paymentPaid: "ચૂકવેલ",
  injection_found: "વિશિષ્ટ અક્ષરો સમર્થિત નથી",
  logout_msg: "શું તમે ખરેખર લૉગઆઉટ કરવા માંગો છો?",
  no: "ના",
  yes: "હા",
  tags_limit: "ટૅગ્સ મર્યાદા ઓવરસિદ્દ કરી છે",
  end_time_less: "અંતનો સમય શરૂઆતના સમય કરતા વધારે હોવો જોઈએ",
  publish_now: "હવે પ્રકાશિત કરો",
  already_publish: "સમાચાર પહેલાથી જ પ્રકાશિત થઈ ચૂક્યું છે.",
  already_publish_notice: "સૂચના પહેલાથી જ પ્રકાશિત થઈ ચૂકી છે.",
  unPublish: "અપ્રકાશિત કરો",
  schedule: "શેડ્યૂલ",
  reSchedule: "ફરીથી શેડ્યૂલ કરો",
  publish: "પ્રકાશિત કરો",
  published: "પ્રકાશિત થયું",
  invalid_amount: "અમાન્ય રકમ",
  tagsChar_limit:
    "ટૅગ ક્ષેત્રમાં 20 અક્ષરોની મર્યાદા છે અને તે વધારે સ્વીકારી શકાતી નથી.",
  // All static content of Dashboard
  dashboard_donationReceived: "મેળવેલું દાન",
  dashboard_donationPending: "બાકી દાન",
  dashboard_all: "બધા",
  donation_hundi: "દાન + હૂંડી",
  dashboard_totalExpenses: "કુલ ખર્ચ",
  noTopDonar: "કોઈ ટોચના દાતાઓ નથી",
  dashboard_RevenueReport: "નાણાકીય અહેવાલ",
  notification_not_found: "કોઈ સૂચના મળી નથી",
  dashboard_monthly: "આ મહિનો",
  dashboard_weekly: "આ સપ્તાહ",
  dashboard_yearly: "આ વર્ષ",
  dashboard_daily: "દૈનિક",

  dashboard_card_title3: "સબ્સ્ક્રાઇબ કરેલ વપરાશકર્તાઓ",
  dashboard_Recent_DonationCommitment: "તાજેતરના દાન",
  dashboard_Recent_DonorName: "દાતા નામ",
  dashboard_Recent_DonorNumber: "મોબાઇલ નંબર",
  dashboard_Recent_DonorType: "પ્રકાર",
  dashboard_Recent_DonorDate: "તારીખ અને સમય",
  dashboard_Recent_DonorStatus: "સ્થિતિ",
  dashboard_Recent_DonorAmount: "રકમ",
  dashboard_Recent_DonorCommitId: "પ્રતિબદ્ધતા ID",
  dashboard_Recent_DonorReceipt: "રસીદ",
  dashboard_viewAll: "બધું જુઓ",
  dashboard_top: "ટોચના 5 દાતાઓ",

  // All static content of News

  news_AddNews: "સમાચાર ઉમેરો",
  news_EditNews: "સમાચાર સંપાદન કરો",
  news_AddLangNews: "ભાષા ઉમેરો",
  news_InputIn: "ભાષા પસંદ કરો:",
  news_label_Title: "શીર્ષક",
  news_label_Tags: "ટૅગ્સ",
  news_label_Description: "વર્ણન ઉમેરો",
  news_label_Date: "પસંદ કરેલી તારીખ",
  news_label_Published: "દ્વારા પ્રકાશિત",
  news_label_ImageVedio: "છબીઓ ઉમેરો",
  news_button_Publish: "પ્રકાશિત કરો",
  news_title_required: "શીર્ષક જરૂરી છે",
  news_tags_required: "ટૅગ્સ જરૂરી છે",
  news_desc_required: "વર્ણન જરૂરી છે",
  news_publish_required: "પ્રકાશકનું નામ જરૂરી છે",
  news_latest_news: "સમાચાર",
  news_btn_AddNews: "સમાચાર ઉમેરો",
  news_popOver_AddLang: "ભાષા ઉમેરો",
  news_popOver_Edit: "સંપાદન",
  news_popOver_Delete: "કાઢી નાખો",

  news_delete: "સમાચાર ડિલીટ કરો",
  news_sure: "શું તમે ખરેખર આ સમાચારને કાયમ માટે કાઢી નાખવા માંગો છો?",
  news_not_found: "સમાચાર નથી",
  news_not_click_add_news:
    "નવી સમાચારનો લેખ ઉમેરવા માટે 'સમાચાર ઉમેરો' પર ક્લિક કરો.",

  // All static content of Events
  same_time: "અંતનો સમય શરૂઆતના સમય જેવો ન હોઈ શકે",
  events_AddEvent: "પ્રવૃત્તિ ઉમેરો",
  events_latest_event: "તાજેતરના ઇવેન્ટ્સ",
  events_title_required: "શીર્ષક જરૂરી છે",
  events_desc_required: "વર્ણન જરૂરી છે",
  events_select_dropDown: "ઇવેન્ટ પસંદ કરો",
  events_select_globle: "ગ્લોબલ ઇવેન્ટ પસંદ કરો",
  events_EditEvent: "ઇવેન્ટ સંપાદન કરો",
  events_startTime_required: "શરૂઆતનો સમય જરૂરી છે",
  events_endTime_required: "અંતનો સમય જરૂરી છે",
  events_startDate_required: "શરૂઆતની તારીખ જરૂરી છે",
  events_endDate_required: "અંતની તારીખ જરૂરી છે",
  events_delete: "ઇવેન્ટ કાઢી નાખો",
  events_sure: "શું તમે ખરેખર આ ઇવેન્ટને કાયમ માટે કાઢી નાખવા માંગો છો?",
  events_not_found: "ઇવેન્ટ્સ નથી",
  events_not_click_add_events:
    "નવી ઇવેન્ટ ઉમેરવા માટે 'ઇવેન્ટ ઉમેરો' પર ક્લિક કરો",

  // All static content of Pashudhan
  cattles_management: "ગૌશાળા",
  cattle_expense_type_assets: "માલમિલકત",
  cattle_dead: "મૃત",
  cattle_alive: "જીવીત",
  cattle_expense_type_consumable: "વપરાશાયોગ્ય",
  cattle_expense_type_general: "સામાન્ય",
  cattle_info: "પશુધન માહિતી",
  cattle_photo: "પશુધનનું ફોટો",
  cattle_owner_photo: "માલિકનો ફોટો",
  cattle_owner_id: "માલિકનો ID",
  cattle_mother_id: "માતાનો ટૅગ ID",
  cattle_breed: "જાત",
  cattle_date_of_birth: "જન્મ તારીખ",
  cattle_age: "ઉંમર",
  cattle_is_pregnant: "ગર્ભવતી છે",
  cattle_is_milking: "દુધ આપે છે",
  cattle_milk_quantity: "દુધની માત્રા",
  cattle_fees: "આવક ખર્ચ",
  cattle_medical_record: "તબીબી રેકોર્ડ",
  cattle_pregnancy_record: "ગર્ભાવસ્થાનો રેકોર્ડ",
  cattle_expense: "ખર્ચ વ્યવસ્થાપન",
  cattle_stock: "સ્ટોક વ્યવસ્થાપન",
  cattle_usage: "વપરાશ વ્યવસ્થાપન",
  cattle_revenueHeading: "દૈનિક નોંધણી રિપોર્ટ",
  recent_registered_cattles: "તાજેતરના નોંધાયેલા પશુધન",
  cattle_registered: "નોંધાયેલ પશુધન",
  cattle_add: "પશુધન ઉમેરો",
  cattle_record_add: "રેકોર્ડ ઉમેરો",
  cattle_breed_add: "જાત ઉમેરો",
  cattle_breed_edit: "જાત સંપાદન કરો",
  cattle_medical_add: "તબીબી રેકોર્ડ ઉમેરો",
  cattle_pregnancy_report: "ગર્ભાવસ્થા રેકોર્ડ",
  cattle_pregnancy_report_add: "ગર્ભાવસ્થા રેકોર્ડ ઉમેરો",
  cattle_expense_add: "ખર્ચ ઉમેરો",
  cattle_supplies_add: "સામાન ઉમેરો",
  cattle_items_add: "સામાન ઉમેરો",
  cattle_id: "પશુધન ID",
  calf_id: "વાછરડું ID",
  cattle_treatment_medicine: "ઉપચાર/દવા",
  cattle_dosage: "ડોઝ",
  cattle_dr_name: "ડૉ.નું નામ",
  cattle_purchase_price: "ખરીદી કિંમત",
  cattle_symptoms: "લક્ષણો",
  cattle_usage_add: "વપરાશ ઉમેરો",
  cattles: "પશુધન",
  cattles_feed: "ચારો",
  cattles_medical: "તબીબી",
  cattles_death: "મૃત્યુ",
  cattles_machinery: "યંત્ર",
  cattle_conceiving_date: "ગર્ભ ધારણ કરવાની તારીખ",
  cattle_pregnancy_date: "ગર્ભાવસ્થા તારીખ",
  cattle_delivery_date: "ડિલિવરી તારીખ",
  cattle_pregnancy_status: "ગર્ભાવસ્થા સ્થિતિ",
  cattle_purpose: "ઉદ્દેશ્ય",
  cattle_itemId: "આઇટમ ID",
  cattle_itemName: "આઇટમ નામ",
  cattle_expense_quantity: "જથ્થો",
  cattle_expense_bill_invoice: "બિલ/ઇનવૉઇસ",
  cattle_expense_bill_invoice_upload: "અપલોડ",
  cattle_unit: "એકમ",
  cattle_expense_unitType: "એકમ પ્રકાર",
  cattle_expense_current_quantity: "વર્તમાન જથ્થો",
  cattle_expense_order_quantity: "ઓર્ડર જથ્થો",
  cattle_last_update: "છેલ્લું અપડેટ",
  cattle_tag_id: "ટૅગ ID",
  cattle_date_purchased: "ખરીદ તારીખ",
  cattle_source: "સ્રોત",
  cattle_owner_name: "માલિકનું નામ",
  cattle_owner_number: "માલિકનો નંબર",
  cattle_owner_image: "માલિકનો ચિત્ર",
  cattle_cow_image: "ગાયનું ચિત્ર",
  cattle_is_dead: "મૃત છે",
  cattle_date_death: "મૃત્યુ તારીખ",
  cattle_death_reason: "મૃત્યુનું કારણ",
  cattle_is_sold: "વેચાયું છે",
  cattle_purchaser_name: "ખરીદનારનું નામ",
  cattle_purchaser_number: "ખરીદનારનો નંબર",
  cattle_purchaser_id: "ખરીદનારનો ID",
  cattle_sold_date: "વેચવાની તારીખ",
  cattle_sold_price: "વેચવાની કિંમત",
  cattle_edit_medical: "તબીબી રેકોર્ડ સંપાદન કરો",
  cattle_edit_pregnancy: "ગર્ભાવસ્થા રેકોર્ડ સંપાદન કરો",
  cattle_edit_cattle: "પશુધન સંપાદન કરો",
  cattle_edit_item: "આઇટમ સંપાદન કરો",
  cattle_edit_supplies: "સામાન સંપાદન કરો",
  cattle_edit_usage: "વપરાશ સંપાદન કરો",
  cattle_category_edit: "શ્રેણી સંપાદન કરો",
  cattle_category_add: "શ્રેણી ઉમેરો",

  // cattle validation messages
  cattle_itemID_required: "આઇટમ ID જરૂરી છે",
  cattle_id_required: "પશુધન ID જરૂરી છે",
  cattle_pregnancy_status_required: "ગર્ભાવસ્થા સ્થિતિ જરૂરી છે",
  cattle_name_required: "નામ જરૂરી છે",
  cattle_order_quantity_required: "ઓર્ડર જથ્થો જરૂરી છે",
  cattle_unit_required: "એકમ જરૂરી છે",
  cattle_unit_type_required: "એકમ પ્રકાર જરૂરી છે",
  cattle_quantity_required: "જથ્થો જરૂરી છે",
  cattle_purpose_required: "ઉદ્દેશ્ય જરૂરી છે",
  cattle_treatment_medicine_required: "ઉપચાર/દવા જરૂરી છે",
  cattle_dosage_required: "ડોઝ જરૂરી છે",
  cattle_DrName_required: "ડૉ.નું નામ જરૂરી છે",
  cattle_price_required: "આવક ખર્ચ જરૂરી છે",
  cattle_symptoms_required: "લક્ષણો જરૂરી છે",
  cattle_tag_id_required: "ટૅગ ID જરૂરી છે",
  cattle_type_required: "શ્રેણી જરૂરી છે",
  cattle_breed_required: "જાત જરૂરી છે",
  cattle_age_required: "ઉંમર જરૂરી છે",
  cattle_purchase_price_required: "ખરીદી કિંમત જરૂરી છે",
  cattle_source_required: "સ્રોત જરૂરી છે",
  cattle_owner_name_required: "માલિકનું નામ જરૂરી છે",
  cattle_owner_id_required: "માલિકનો ID જરૂરી છે",
  cattle_deathReason_required: "મૃત્યુનું કારણ જરૂરી છે",
  cattle_purchaser_name_required: "ખરીદનારનું નામ જરૂરી છે",
  cattle_purchaser_id_required: "ખરીદનારનો ID જરૂરી છે",
  cattle_sold_price_required: "વેચવાની કિંમત જરૂરી છે",
  cattle_milk_quantity_required: "દુધની માત્રા જરૂરી છે",

  // all cattle management delete messages
  cattle_cattle_delete: "પશુધન કાઢી નાખો",
  cattle_cattle_sure: "શું તમે ખરેખર આ પશુધન કાઢી નાખવા માંગો છો?",
  cattle_medical_delete: "તબીબી માહિતી કાઢી નાખો",
  cattle_medical_sure: "શું તમે ખરેખર આ તબીબી રિપોર્ટ કાઢી નાખવા માંગો છો?",
  cattle_pregnancy_delete: "ગર્ભાવસ્થા રિપોર્ટ કાઢી નાખો",
  cattle_pregnancy_sure:
    "શું તમે ખરેખર આ ગર્ભાવસ્થા રિપોર્ટ કાઢી નાખવા માંગો છો?",
  cattle_supplies_delete: "સામાન કાઢી નાખો",
  cattle_supplies_sure: "શું તમે ખરેખર આ સપ્લાય કાઢી નાખવા માંગો છો?",

  cattle_usage_delete: "વપરાશ કાઢી નાખો",
  cattle_usage_sure: "શું તમે ખરેખર આ વપરાશ કાઢી નાખવા માંગો છો?",

  cattle_item_delete: "આઇટમ કાઢી નાખો",
  cattle_item_sure: "શું તમે ખરેખર આ આઇટમ કાઢી નાખવા માંગો છો?",
  cattle_breed_delete: "જાત કાઢી નાખો",
  cattle_breed_sure: "શું તમે ખરેખર આ જાત કાઢી નાખવા માંગો છો?",

  // All cattle search variable
  search_cattles: "પશુધનID, શ્રેણી, જાત દ્વારા શોધો",
  search_cattles_medical: "પશુધન તબીબી માહિતી શોધો",
  search_cattles_pregnancy: "પશુધન ગર્ભાવસ્થા રિપોર્ટ શોધો",
  search_cattles_stock: "સ્ટોક શોધો",
  search_cattles_supplies: "આઇટમ સપ્લાય શોધો",
  search_cattles_usage: "આઇટમ વપરાશ શોધો",
  search_cattles_items: "પશુધન આઇટમ શોધો",
  // All static content of Notices

  notices_AddNotice: "સૂચના ઉમેરો",
  notices_latest_Notice: "સૂચનાઓ",
  add_image: "છબી ઉમેરો",
  image_size_suggestion: "(કૃપા કરીને 450px*350px આકારની છબી અપલોડ કરો)",
  notices_title_required: "શીર્ષક જરૂરી છે",
  notices_desc_required: "વર્ણન જરૂરી છે",
  notices_select_dropDown: "સૂચના પસંદ કરો",
  notices_EditNotice: "સૂચના સંપાદન કરો",

  notices_delete: "સૂચના કાઢી નાખો",
  notices_sure: "શું તમે ખરેખર આ સૂચનાને કાયમ માટે કાઢી નાખવા માંગો છો?",
  notices_not_found: "સૂચનાઓ નથી",
  notices_not_click_add_notices:
    "નવી સૂચના ઉમેરવા માટે 'સૂચના ઉમેરો' પર ક્લિક કરો.",

  // All static content of Categories
  only_letters: "ઉપ શ્રેણી ફક્ત અક્ષરો હોવી જોઈએ",
  categories_AddCategory: "ઉપ શ્રેણી ઉમેરો",
  categories_serial_number: "સિરિયલ નંબર",
  categories_master_category: "માસ્ટર શ્રેણી",
  categories_sub_category: "ઉપ શ્રેણી",
  categories_category_required: "માસ્ટર શ્રેણી જરૂરી છે",
  categories_sub_category_required: "ઉપ શ્રેણી જરૂરી છે",
  categories_latest_Category: "શ્રેણીઓ",
  categories_title_required: "શ્રેણીનું નામ જરૂરી છે",
  categories_EditCategory: "શ્રેણી સંપાદન કરો",
  categories_select_category: "શ્રેણી પસંદ કરો",
  categories_select_amount: "રકમ દાખલ કરો",
  category_select_sub_category: "ઉપ શ્રેણી પસંદ કરો",
  categories_select_user_name: "વપરાશકર્તાનું નામ પસંદ કરો",
  category_select: "પસંદ કરો",
  categories_select_master_category: "માસ્ટર શ્રેણી પસંદ કરો",
  donar_name_required: "દાતા નામ જરૂરી છે",
  created_by_required: "બનાવનારાનું નામ જરૂરી છે",
  user_select_required: "વપરાશકર્તાનું નામ જરૂરી છે",
  masterCategory_required: "શ્રેણી જરૂરી છે",
  subCategory_required: "ઉપ શ્રેણી પસંદ કરો",
  commitmentID_required: "પ્રતિબદ્ધતા ID પસંદ કરો",
  amount_required: "રકમ જરૂરી છે",

  category_delete: "શ્રેણી કાઢી નાખો",
  category_sure: "શું તમે ખરેખર આ શ્રેણીને કાયમ માટે કાઢી નાખવા માંગો છો?",
  category_not_found: "શ્રેણીઓ ઉપલબ્ધ નથી",
  category_not_click_add_category:
    "નવી શ્રેણી ઉમેરવા માટે 'શ્રેણી ઉમેરો' પર ક્લિક કરો.",

  // All static content of Users
  users_AddUser: "સબ એડમિન ઉમેરો",
  users_latest_User: "સબ એડમિન",
  users_title_required: "વપરાશકર્તાનું નામ જરૂરી છે",
  user_only_letters: "વપરાશકર્તાનું નામ ફક્ત અક્ષરો હોવું જોઈએ",
  users_mobile_required: "મોબાઇલ નંબર જરૂરી છે",
  mobile_number_limit: "મોબાઇલ નંબર 10 આંકનો હોવો જોઈએ",
  users_email_required: "ઈમેઇલ જરૂરી છે",
  users_not_found: "સબ એડમિન નથી",
  users_not_click_add_users:
    "નવો સબ એડમિન વપરાશકર્તા ઉમેરવા માટે 'સબ એડમિન ઉમેરો' પર ક્લિક કરો.",

  // All static content of Internal Expense
  expenses_AddExpenses: "ખર્ચ ઉમેરો",
  price_per_item: "પ્રતિ આઇટમ કિંમત",
  expense_type_required: "ખર્ચ પ્રકાર જરૂરી છે",
  expenses_EditExpenses: "ખર્ચ સંપાદન કરો",
  expenses_Date: "તારીખ",
  expence_description: "વર્ણન",
  expenses_latest_Expenses: "બધા ખર્ચ",
  expenses_title_required: "શીર્ષક જરૂરી છે",
  expenses_mobile_required: "મોબાઇલ નંબર જરૂરી છે",
  expenses_email_required: "ઈમેઇલ જરૂરી છે",
  added_by: "દ્વારા ઉમેર્યું",
  expenses_desc_required: "વર્ણન જરૂરી છે",
  expence_amount_required: "રકમ જરૂરી છે",

  expence_delete: "ખર્ચ કાઢી નાખો",
  expence_sure: "શું તમે ખરેખર આ ખર્ચને કાયમ માટે કાઢી નાખવા માંગો છો?",
  expence_not_found: "ખર્ચ નથી",
  expence_not_click_add_expence:
    "નવા ખર્ચ નોંધવા માટે 'ખર્ચ ઉમેરો' પર ક્લિક કરો.",

  // All static content of Financial Report

  report_AddReport: "નાણાકીય અહેવાલ",
  report_expences: "ખર્ચ",
  remarks_financial_donationBox: "ટીકાઓ",
  report_commitment: "પ્રતિબદ્ધતા",
  reports_not_found: "અહેવાલ અને વિવાદ નથી",
  report_donation_box: "દાનપેટી સંકલન",
  finance_report_no_found:
    "પસંદ કરેલ સમયગાળા માટે કોઈ નાણાકીય અહેવાલ ઉપલબ્ધ નથી",
  financial_not_found: "કોઈ નાણાકીય વિગતો મળી નથી",

  // All static content of Donation
  donation_Donation: "દાન",
  start_Time: "શરૂઆતનો સમય",
  end_Time: "અંતનો સમય",
  is_government: "સરકારી દાતા છે",
  donation_Adddonation: "દાન ઉમેરો",
  original_amount: "મૂળ રકમ",
  estimate_amount: "અંદાજિત રકમ",
  donation_select_date_time: "તારીખ પસંદ કરો",
  donation_select_date: "તારીખ પસંદ કરો",
  donation_donar_name_only_letters: "દાતા નામ ફક્ત અક્ષરો હોવું જોઈએ",
  add_user_donation: "કોઈ વપરાશકર્તા મળ્યા નથી, ક્લિક કરો",
  donation_delete: "દાન કાઢી નાખો",
  donation_sure: "શું તમે ખરેખર આ દાનને કાયમ માટે કાઢી નાખવા માંગો છો?",
  donation_not_found: "કોઈ દાન મળ્યું નથી",
  add_user: "વપરાશકર્તા ઉમેરો",
  donation_paid_not_found: "કોઈ ચુકવેલ દાન મળ્યા નથી",
  donation_not_click_add_donation:
    'સ્ક્રીન પર દાન ઉમેરવા માટે "દાન ઉમેરો" પર ક્લિક કરો',

  // All static content of Donation Box
  DonationBox_total_collection: "કુલ સંકલન:",
  DonationBox_DonationBox: "દાનપેટી સંકલન",
  DonationBox_AddCollectionBox: "સંકલન ઉમેરો",
  DonationBox_EditCollectionBox: "સંકલન સંપાદન",
  created_by: "દ્વારા બનાવેલું",
  edit_collection: "સંકલન સંપાદન કરો",
  enter_price_manually: "રકમ દાખલ કરો",
  viewLogs: "લોગ્સ જુઓ",
  logs: "લોગ્સ",
  add_remarks: "ટીપ્પણીઓ ઉમેરો",
  add_amount: "રકમ ઉમેરો",
  DonationBox_AddDonationBox: "દાનપેટી ઉમેરો",
  donation_box_desc_required: "વર્ણન જરૂરી છે",
  donation_box_amount_required: "રકમ જરૂરી છે",

  logData_editedBy: "દ્વારા સંપાદિત",
  logData_createdBy: "દ્વારા બનાવેલું",
  logData_timeDate: "સમય અને તારીખ",
  logData_createdAmount: "બનાવેલી રકમ",
  logData_editedAmount: "સંપાદિત રકમ",

  donation_box_not_found: "દાનપેટી મળ્યાં નથી",
  donation_box_not_click_add_donation_box:
    "નવી દાનપેટી ઉમેરવા માટે 'સંકલન ઉમેરો' પર ક્લિક કરો",

  // All Commitment Data
  notify_user: "વપરાશકર્તાને સૂચિત કરો",
  notify_user_content: "વપરાશકર્તાને જાણ કરવા માટે ચેક બોક્સ તપાસો",
  commitment_Username: "વપરાશકર્તા નામ",
  add_commitment: "પ્રતિબદ્ધતા ઉમેરો",
  edit_commitment: "પ્રતિબદ્ધતા સંપાદન કરો",
  commitment_end_Date: "અંતની તારીખ",
  commitment_Amount_Due: "બાકી રકમ",
  commitment_select_commitment_id: "પ્રતિબદ્ધતા ID પસંદ કરો",
  commitment_select_start_date: "શરૂઆતની તારીખ પસંદ કરો",
  commitment_select_end_date: "અંતની તારીખ પસંદ કરો",

  commitment_delete: "પ્રતિબદ્ધતા કાઢી નાખો",
  commitment_sure:
    "શું તમે ખરેખર આ પ્રતિબદ્ધતાને કાયમ માટે કાઢી નાખવા માંગો છો?",
  commitment_not_found: "પ્રતિબદ્ધતાઓ મળી નથી",
  commitment_not_click_add_commitment:
    "નવી પ્રતિબદ્ધતા બનાવવા માટે 'પ્રતિબદ્ધતા ઉમેરો' પર ક્લિક કરો.",
  commitment_complete: "પૂર્ણ",
  commitment_partial: "આંશિક",

  // All subscribed user static data
  subscribed_user_add_user: "સબ્સ્ક્રાઇબ કરેલ વપરાશકર્તા ઉમેરો",
  subscribed_user_name: "વપરાશકર્તા નામ",
  subscribed_user_pan_cardDetail: "PAN કાર્ડ વિગતો",
  subscribed_user_date_birth: "જન્મ તારીખ",
  subscribed_user_email: "ઈમેઇલ",
  subscribed_user_address: "સરનામું",
  user_name: "વપરાશકર્તા નામ",
  subscribed_not_found: "કોઈ સબ્સ્ક્રાઇબ કરેલ વપરાશકર્તા મળ્યા નથી",
  subscribed_not_click_add:
    'સ્ક્રીન પર સબ્સ્ક્રાઇબ કરેલ વપરાશકર્તા ઉમેરવા માટે "સબ્સ્ક્રાઇબ કરેલ વપરાશકર્તા ઉમેરો" પર ક્લિક કરો',

  // All report & dispute static data
  report_report_against: "નામ",
  report_mobile: "મોબાઇલ નંબર",
  export_report: "અહેવાલ નિકાસ કરો",
  report_Transaction_IDs: "ટ્રાન્ઝેક્શન IDs",
  report_panding: "બાકી",
  report_re_solved: "ફરીથી ઉકેલાઈ",
  report_dispute_not_found: "અહેવાલ અને વિવાદ મળ્યા નથી",

  // All notification static data
  notifications: "સૂચનાઓ",
  notifications_Notify_Users: "વપરાશકર્તાઓને સૂચિત કરો",
  notifications_not_found: "કોઈ સૂચના મળી નથી",

  // All user Profile static Data
  about_trust: "અમારા વિશે",
  trust_address: "સરનામું",
  trust_user: "વપરાશકર્તા વિશે",
  trust_location: "સ્થાન",
  trust_facility: "સુવિધાઓ",
  trust_facebookLink: "ફેસબુક લિંક",
  trust_facebookLink_required: "ફેસબુક લિંક જરૂરી છે",
  trust_contact_number_required: "સંપર્ક નંબર જરૂરી છે",
  contact_number: "સંપર્ક નંબર",
  trust_image_certificate: "છબીઓ અને પ્રમાણપત્રો",
  location: "સ્થાન",
  trust_prefenses: "ટ્રસ્ટ પસંદગીઓ",
  trust_prefenses_required: "ટ્રસ્ટ પસંદગીઓ જરૂરી છે",
  latitude: "અક્ષાંશ",
  longitude: "રેખાંશ",
  userProfile: "પ્રોફાઇલ",
  user_editUser: "વપરાશકર્તા સંપાદન કરો",
  user_userRoleRequired: "વપરાશકર્તા ભૂમિકા જરૂરી છે",
  minimum_one_role_required: "એક વપરાશકર્તા ભૂમિકા પસંદ કરવાની જરૂરી છે",
  password_required: "પાસવર્ડ જરૂરી છે",
  userProfile_phone_number: "ફોન નંબર",
  trust_trustType: "ટ્રસ્ટ પ્રકાર",
  about_trusts: "વિશે",
  userProfile_name: "નામ",
  trust_type_required: "ટ્રસ્ટ પ્રકાર જરૂરી છે",
  trust_about_required: "ટ્રસ્ટ વિશેની માહિતી જરૂરી છે",
  State: "રાજ્ય",
  City: "શહેર",
  events_state_required: "રાજ્ય જરૂરી છે",
  events_city_required: "શહેર જરૂરી છે",
  events_location_required: "સ્થાન જરૂરી છે",
  facilities: "સુવિધાઓ",
  userProfile_email_id: "ઈમેઇલ",
  userProfile_temple: "મંદિર",
  longitude_required: "રેખાંશ જરૂરી છે",
  latitude_required: "અક્ષાંશ જરૂરી છે",
  user_password: "પાસવર્ડ",
  user_password_old: "જૂનો પાસવર્ડ",
  user_password_new: "નવો પાસવર્ડ",
  edit_image: "છબી સંપાદન કરો",
  user_password_confirm: "પાસવર્ડની પુષ્ટિ કરો",
  userProfile_trust_type: "ટ્રસ્ટ પ્રકાર",
  userProfile_doc_certificate: "દસ્તાવેજ/પ્રમાણપત્ર",
  userProfile_facilities: "સુવિધાઓ",
  userProfile_punyarjak: "પુણ્યર્જક",
  userProfile_temple_upload_doc: "તમારા મંદિરનો દસ્તાવેજ અપલોડ કરો",
  user_userRole: "વપરાશકર્તા ભૂમિકાઓ",
  images: "છબીઓ",
  browse: "બ્રાઉઝ",
  update_profile: "પ્રોફાઇલ અપડેટ કરો",
  name_required: "નામ જરૂરી છે",
  start_time: "શરૂઆતનો સમય",
  start_time_required: "શરૂઆતનો સમય જરૂરી છે",
  end_time: "અંતનો સમય",
  end_time_required: "અંતનો સમય જરૂરી છે",

  email_invalid: "અમાન્ય ઈમેઇલ",
  email_required: "ઈમેઇલ જરૂરી છે",
  number_type: "તે ફોન નંબર નથી લાગતો",
  cant_start_minus: "ફોન નંબર માઇનસ સાથે શરૂ થઈ શકતો નથી",
  number_in_point: "ફોન નંબરમાં દશાંશ બિંદુ ન હોઈ શકે",
  number_required: "ફોન નંબર જરૂરી છે",
  address_required: "સરનામું જરૂરી છે",
  Temple_name_required: "મંદિરનું નામ જરૂરી છે",
  doc_required: "મંદિરનો દસ્તાવેજ જરૂરી છે",

  // punyarjak

  punyarjak: "પુણ્યર્જક",
  add_punyarjak: "પુણ્યર્જક ઉમેરો",
  img_required: "છબી જરૂરી છે",
  edit_punyarjak: "પુણ્યર્જક સંપાદન કરો",
  name: "નામ",
  description: "વર્ણન",
  punyarjak_desc_required: "વર્ણન જરૂરી છે",
  punyarjak_delete: "પુણ્યર્જક કાઢી નાખો",
  username: "વપરાશકર્તાનું નામ ફક્ત અક્ષરો હોવું જોઈએ",
  punyarjak_sure: "શું તમે ખરેખર આ પુણ્યર્જકને કાયમ માટે કાઢી નાખવા માંગો છો?",
  punyarjak_not_found: "પુણ્યર્જક વપરાશકર્તાઓ નથી",
  punyarjak_not_click_add:
    "નવા પુણ્યર્જક વપરાશકર્તાને ઉમેરવા માટે 'પુણ્યર્જક ઉમેરો' પર ક્લિક કરો",

  // all place holder images

  placeHolder_donar_name: "દાતા નામ દાખલ કરો",
  placeHolder_price_per_item: "પ્રતિ આઇટમ કિંમત દાખલ કરો",
  placeHolder_mobile_number: "મોબાઇલ નંબર દાખલ કરો",
  placeHolder_phone_number: "સંપર્ક નંબર દાખલ કરો",
  placeHolder_title: "શીર્ષક દાખલ કરો",
  placeHolder_description: "વર્ણન દાખલ કરો",
  placeHolder_tags: "ટૅગ્સ દાખલ કરો",
  placeHolder_name: "નામ દાખલ કરો",
  placeHolder_user_name: "વપરાશકર્તા નામ દાખલ કરો",
  placeHolder_email: "ઈમેઇલ દાખલ કરો",
  placeHolder_password: "પાસવર્ડ દાખલ કરો",
  placeHolder_sub_category: "ઉપ શ્રેણી દાખલ કરો",
  placeHolder_city: "શહેર દાખલ કરો",
  placeHolder_state: "રાજ્ય દાખલ કરો",
  placeHolder_location: "સ્થાન દાખલ કરો",
  placeHolder_trust_facebook: "ફેસબુક લિંક દાખલ કરો",
  placeHolder_trust_address: "સરનામું દાખલ કરો",
  placeHolder_cattle_id: "પશુધન ID પસંદ કરો",
  placeHolder_calf_id: "વાછરડું ID પસંદ કરો",
  placeHolder_cattle_treatment_medicine: "ઉપચાર/દવા દાખલ કરો",
  placeHolder_cattle_dosage: "ડોઝ દાખલ કરો",
  placeHolder_cattle_dr_name: "ડૉ.નું નામ દાખલ કરો",
  placeHolder_cattle_purchase_price: "ખરીદી કિંમત દાખલ કરો",
  placeHolder_cattle_fees: "આવક ખર્ચ દાખલ કરો",
  placeHolder_cattle_symptoms: "લક્ષણો દાખલ કરો",
  placeHolder_cattle_pregnancy_status: "ગર્ભાવસ્થા સ્થિતિ દાખલ કરો",
  placeHolder_cattle_itemId: "આઇટમ ID પસંદ કરો",
  placeHolder_cattle_item_name: "આઇટમ નામ પસંદ કરો",
  placeHolder_cattle_expense_quantity: "જથ્થો દાખલ કરો",
  placeHolder_cattle_unit: "જથ્થો એકમ પસંદ કરો",
  placeHolder_cattle_expense_unitType: "એકમ પ્રકાર પસંદ કરો",
  placeHolder_cattle_purpose: "ઉદ્દેશ્ય દાખલ કરો",
  placeHolder_cattle_item_id: "આઇટમ નામ દાખલ કરો",
  placeHolder_cattle_expense_current_quantity: "વર્તમાન જથ્થો દાખલ કરો",
  placeHolder_cattle_expense_order_quantity: "ઓર્ડર જથ્થો દાખલ કરો",
  placeholder_cattle_type: "પશુધન શ્રેણી પસંદ કરો",
  placeHolder_cattle_breed: "પશુધન જાત પસંદ કરો",
  placeHolder_cattle_age: "ઉંમર દાખલ કરો",
  placeHolder_cattle_source: "સ્રોત પસંદ કરો",
  placeHolder_cattle_owner_name: "માલિકનું નામ દાખલ કરો",
  placeHolder_cattle_owner_Id: "માલિકનો ID દાખલ કરો",
  placeHolder_cattle_tag_id: "ટૅગ ID દાખલ કરો",
  placeholder_cattle_mother_id: "માતાનો ટૅગ ID દાખલ કરો",
  placeHolder_cattle_owner_number: "માલિકનો નંબર દાખલ કરો",
  placeHolder_cattle_death_reason: "મૃત્યુનું કારણ દાખલ કરો",
  placeHolder_cattle_purchaser_name: "ખરીદનારનું નામ દાખલ કરો",
  placeHolder_cattle_purchaser_number: "ખરીદનારનો નંબર દાખલ કરો",
  placeHolder_cattle_purchaser_id: "ખરીદનારનો ID દાખલ કરો",
  placeHolder_cattle_sold_price: "વેચવાની કિંમત દાખલ કરો",
  placeHolder_cattle_milk_quantity: "દુધની માત્રા લિટરમાં દાખલ કરો",
  placeHolder_expense_type: "ખર્ચ પ્રકાર પસંદ કરો",

  // All search static keys
  search_notification: "બધી સૂચનાઓ શોધો",
  search_subscribe_user: "બધા સબ્સ્ક્રાઇબ વપરાશકર્તાઓ શોધો",
  search_news: "બધા સમાચાર શોધો",
  search_events: "બધી પ્રવૃત્તિઓ શોધો",
  search_notices: "બધી સૂચનાઓ શોધો",
  search_categories: "બધી શ્રેણીઓ શોધો",
  search_users: "બધા વપરાશકર્તાઓ શોધો",
  search_disputes: "બધા વિવાદો શોધો",
  search_expenses: "બધા ખર્ચ શોધો",
  search_financial_reports: "બધા નાણાકીય અહેવાલો શોધો",
  search_donation: "બધા દાન શોધો",
  search_punyarjak: "બધા પુણ્યર્જક શોધો",
  search_donation_box_collection: "બધા દાનપેટી સંકલન શોધો",
  search_commitment: "બધી પ્રતિબદ્ધતાઓ શોધો",
};
