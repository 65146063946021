export const kannadaContent = {
  no_data_found: "ಯಾವುದೇ ಡೇಟಾ ಕಂಡುಬಂದಿಲ್ಲ",
  no_data_found_add_data: 'ತಿರುವಳಿಗೆ ಡೇಟಾ ಸೇರಿಸಲು "ಸೇರಿಸು" ಬಟನ್ ಕ್ಲಿಕ್ ಮಾಡಿ',
  amount: "ಮೊತ್ತ",
  all: "ಎಲ್ಲಾ",
  dashboard: "ಡ್ಯಾಶ್‌ಬೋರ್ಡ್",
  donation: "ದೇಣಿಗೆ",
  commitment: "ಬದ್ಧತೆ",
  hundi: "ಹುಂಡಿ ಸಂಗ್ರಹ",
  financial_reports: "ಹಣಕಾಸು ವರದಿಗಳು",
  internal_expenses: "ಖರ್ಚುಗಳು",
  total_donation: "ಒಟ್ಟು ದೇಣಿಗೆ:",
  events: "ಕಾರಂಭಗಳು",
  total: "ಒಟ್ಟು:",
  Select: "ಆಯ್ಕೆ",
  news: "ಸುದ್ದಿ",
  notices: "ಅವಿಷ್ಕಾರ",
  configuration: "ವಿನ್ಯಾಸ",
  searchBarPlaceHolder: "ಶೋಧಿಸಿ ದೇವಸ್ಥಾನಗಳು, ಟ್ರಸ್ಟ್, ಕಾರ್ಯಕ್ರಮಗಳು, ಸುದ್ದಿ",
  monthName_January: "ಜನವರಿ",
  monthName_February: "ಫೆಬ್ರವರಿ",
  monthName_March: "ಮಾರ್ಚ್",
  monthName_April: "ಎಪ್ರಿಲ್",
  monthName_May: "ಮೇ",
  monthName_June: "ಜೂನ್",
  monthName_July: "ಜುಲೈ",
  monthName_August: "ಆಗಸ್ಟ್",
  monthName_September: "ಸೆಪ್ಟೆಂಬರ್",
  monthName_October: "ಅಕ್ಟೋಬರ್",
  monthName_November: "ನವೆಂಬರ್",
  monthName_December: "ಡಿಸೆಂಬರ್",
  last_login: "ಕೊನೆಯದಾಗಿ ಲಾಗ್ ಇನ್ ಆದವರು",
  category: "ವರ್ಗ",
  user: "ಉಪ ನಿರ್ವಾಹಕರು",
  report_Dispute: "ವರದಿ & ವ್ಯಾಜ್ಯ",
  cancel: "ರದ್ದುಮಾಡು",
  confirm: "ಡಿಲೀಟ್ ದೃಢೀಕರಿಸಿ",
  save_changes: "ಬದಲಾವಣೆಗಳನ್ನು ಉಳಿಸು",
  rupees: "ರೂ",
  downloadReceipt: "ರಶೀದಿ ಡೌನ್ಲೋಡ್ ಮಾಡಿ",
  pay_donation: "ದೇಣಿಗೆ ಕೊಡು",
  payment: "ಇತ್ತೀಚಿನ",
  paymentPaid: "ಪಾವತಿಸಲಾಗಿದೆ",
  injection_found: "ವಿಶೇಷ ಚಿಹ್ನೆಗಳು ಬೆಂಬಲಿತವಲ್ಲ",
  logout_msg: "ನೀವು ಲಾಗ್ ಔಟ್ ಆಗಲು ಇಚ್ಛಿಸುತ್ತೀರಾ?",
  no: "ಇಲ್ಲ",
  yes: "ಹೌದು",
  tags_limit: "ಟ್ಯಾಗ್ ಮಿತಿ ಮೀರಿ",
  end_time_less: "ಅಂತ್ಯದ ಸಮಯವು ಪ್ರಾರಂಭದ ಸಮಯಕ್ಕಿಂತ ಹೆಚ್ಚಿನದಾಗಿರಬೇಕು",
  publish_now: "ಈಗ ಪ್ರಕಟಿಸಿ",
  already_publish: "ಸುದ್ದಿಯನ್ನು ಈಗಾಗಲೇ ಪ್ರಕಟಿಸಲಾಗಿದೆ.",
  already_publish_notice: "ನೋಟೀಸ್ ಈಗಾಗಲೇ ಪ್ರಕಟಿಸಲಾಗಿದೆ.",
  unPublish: "ಅಪ್ರಕಟಿತ",
  schedule: "ಅನಿಸ್ಸು",
  reSchedule: "ಮರು ಸೂಚನೆ",
  publish: "ಪ್ರಕಟಿಸಿ",
  published: "ಪ್ರಕಟಿತ",
  invalid_amount: "ಅಮಾನ್ಯ ಮೌಲ್ಯ",
  tagsChar_limit:
    "ಟ್ಯಾಗ್ ಕ್ಷೇತ್ರಕ್ಕೆ 20 ಅಕ್ಷರಗಳ ಮಿತಿಯಿದೆ ಮತ್ತು ಅದಕ್ಕಿಂತ ಹೆಚ್ಚು ಸ್ವೀಕರಿಸದು.",
  // All static content of Dashboard
  dashboard_donationReceived: "ದೇಣಿಗೆ ಪಡೆದಿದೆ",
  dashboard_donationPending: "ಬಾಕಿ ದೇಣಿಗೆ",
  dashboard_all: "ಎಲ್ಲಾ",
  donation_hundi: "ದೇಣಿಗೆ + ಹುಂಡಿ",
  dashboard_totalExpenses: "ಒಟ್ಟು ಖರ್ಚುಗಳು",
  noTopDonar: "ಇಲ್ಲಿ ಯಾರೂ ಇಲ್ಲ",
  dashboard_RevenueReport: "ಹಣಕಾಸು ವರದಿ",
  notification_not_found: "ಯಾವುದೇ ಸೂಚನೆ ಕಂಡುಬಂದಿಲ್ಲ",
  dashboard_monthly: "ಈ ತಿಂಗಳು",
  dashboard_weekly: "ಈ ವಾರ",
  dashboard_yearly: "ಈ ವರ್ಷ",
  dashboard_daily: "ದೈನಂದಿನ",

  dashboard_card_title3: "ಚಂದಾದಾರರು",
  dashboard_Recent_DonationCommitment: "ಇತ್ತೀಚಿನ ದೇಣಿಗೆಗಳು",
  dashboard_Recent_DonorName: "ದಾತನ ಹೆಸರು",
  dashboard_Recent_DonorNumber: "ಮೊಬೈಲ್ ನಂಬರ್",
  dashboard_Recent_DonorType: "ತಳಿರ",
  dashboard_Recent_DonorDate: "ದಿನಾಂಕ & ಸಮಯ",
  dashboard_Recent_DonorStatus: "ಸ್ಥಿತಿ",
  dashboard_Recent_DonorAmount: "ಮೊತ್ತ",
  dashboard_Recent_DonorCommitId: "ಬದ್ಧತಾ ID",
  dashboard_Recent_DonorReceipt: "ರಶೀದಿ",
  dashboard_viewAll: "ಎಲ್ಲಾ ವೀಕ್ಷಿಸಿ",
  dashboard_top: "ಟಾಪ್ 5 ದಾತರು",

  // All static content of News

  news_AddNews: "ಸುದ್ದಿ ಸೇರಿಸಿ",
  news_EditNews: "ಸುದ್ದಿ ಸಂಪಾದಿಸಿ",
  news_AddLangNews: "ಭಾಷೆ ಸೇರಿಸಿ",
  news_InputIn: "ಭಾಷೆ ಆಯ್ಕೆಮಾಡಿ:",
  news_label_Title: "ಶೀರ್ಷಿಕೆ",
  news_label_Tags: "ಟ್ಯಾಗ್ಗಳು",
  news_label_Description: "ವಿವರಣೆ ಸೇರಿಸಿ",
  news_label_Date: "ಆಯ್ಕೆ ಮಾಡಿದ ದಿನಾಂಕ",
  news_label_Published: "ಪ್ರಕಟಿಸಿದವರು",
  news_label_ImageVedio: "ಚಿತ್ರಗಳು ಸೇರಿಸಿ",
  news_button_Publish: "ಪ್ರಕಟಿಸಿ",
  news_title_required: "ಶೀರ್ಷಿಕೆ ಅಗತ್ಯವಿದೆ",
  news_tags_required: "ಟ್ಯಾಗ್ಗಳು ಅಗತ್ಯವಿದೆ",
  news_desc_required: "ವಿವರಣೆ ಅಗತ್ಯವಿದೆ",
  news_publish_required: "ಪ್ರಕಟಿಸಿದವರ ಹೆಸರು ಅಗತ್ಯವಿದೆ",
  news_latest_news: "ಸುದ್ದಿ",
  news_btn_AddNews: "ಸುದ್ದಿ ಸೇರಿಸಿ",
  news_popOver_AddLang: "ಭಾಷೆ ಸೇರಿಸಿ",
  news_popOver_Edit: "ಸಂಪಾದಿಸಿ",
  news_popOver_Delete: "ಅಳಿಸಿ",

  news_delete: "ಸುದ್ದಿ ಅಳಿಸು",
  news_sure: "ನೀವು ಖಚಿತವಾಗಿ ಈ ಸುದ್ದಿಯನ್ನು ಶಾಶ್ವತವಾಗಿ ಅಳಿಸಲು ಬಯಸುತ್ತೀರಾ?",
  news_not_found: "ಸುದ್ದಿ ಕಾಣಲಿಲ್ಲ",
  news_not_click_add_news:
    "ಹೊಸ ಸುದ್ದಿಯನ್ನು ಸೇರಿಸಲು, 'ಸುದ್ದಿ ಸೇರಿಸಿ' ಕ್ಲಿಕ್ ಮಾಡಿ.",

  // All static content of Events
  same_time: "ಅಂತ್ಯದ ಸಮಯವು ಪ್ರಾರಂಭದ ಸಮಯದಂತೆಯೇ ಇರಲು ಸಾಧ್ಯವಿಲ್ಲ",
  events_AddEvent: "ಕಾರ್ಯಕ್ರಮ ಸೇರಿಸಿ",
  events_latest_event: "ಇತ್ತೀಚಿನ ಕಾರ್ಯಕ್ರಮಗಳು",
  events_title_required: "ಶೀರ್ಷಿಕೆ ಅಗತ್ಯವಿದೆ",
  events_desc_required: "ವಿವರಣೆ ಅಗತ್ಯವಿದೆ",
  events_select_dropDown: "ಕಾರ್ಯಕ್ರಮ ಆಯ್ಕೆಮಾಡಿ",
  events_select_globle: "ಜಾಗತಿಕ ಕಾರ್ಯಕ್ರಮ ಆಯ್ಕೆಮಾಡಿ",
  events_EditEvent: "ಕಾರ್ಯಕ್ರಮ ಸಂಪಾದಿಸಿ",
  events_startTime_required: "ಪ್ರಾರಂಭದ ಸಮಯ ಅಗತ್ಯವಿದೆ",
  events_endTime_required: "ಅಂತ್ಯದ ಸಮಯ ಅಗತ್ಯವಿದೆ",
  events_startDate_required: "ಪ್ರಾರಂಭದ ದಿನಾಂಕ ಅಗತ್ಯವಿದೆ",
  events_endDate_required: "ಅಂತ್ಯದ ದಿನಾಂಕ ಅಗತ್ಯವಿದೆ",
  events_delete: "ಕಾರ್ಯಕ್ರಮ ಅಳಿಸು",
  events_sure: "ನೀವು ಖಚಿತವಾಗಿ ಈ ಕಾರ್ಯಕ್ರಮವನ್ನು ಶಾಶ್ವತವಾಗಿ ಅಳಿಸಲು ಬಯಸುತ್ತೀರಾ?",
  events_not_found: "ಕಾರ್ಯಕ್ರಮಗಳು ಕಾಣಲಿಲ್ಲ",
  events_not_click_add_events:
    "ಹೊಸ ಕಾರ್ಯಕ್ರಮವನ್ನು ಸೇರಿಸಲು, 'ಕಾರ್ಯಕ್ರಮ ಸೇರಿಸಿ' ಕ್ಲಿಕ್ ಮಾಡಿ.",

  // All static content of Pashudhan
  cattles_management: "ಗೌಶಾಲೆ",
  cattle_expense_type_assets: "ಸಂಪತ್ತು",
  cattle_dead: "ಸತ್ತ",
  cattle_alive: "ಬದುಕಿರುವ",
  cattle_expense_type_consumable: "ಬಳಕೆ",
  cattle_expense_type_general: "ಸಾಮಾನ್ಯ",
  cattle_info: "ಪಶುಧನ ಮಾಹಿತಿ",
  cattle_photo: "ಪಶುಧನದ ಚಿತ್ರ",
  cattle_owner_photo: "ಮಾಲೀಕನ ಚಿತ್ರ",
  cattle_owner_id: "ಮಾಲೀಕನ ID",
  cattle_mother_id: "ತಾಯಿಯ ಟ್ಯಾಗ್ ID",
  cattle_breed: "ಬೀಜ",
  cattle_date_of_birth: "ಹುಟ್ಟುಹಬ್ಬದ ದಿನಾಂಕ",
  cattle_age: "ವಯಸ್ಸು",
  cattle_is_pregnant: "ಗರ್ಭಿಣಿ",
  cattle_is_milking: "ಹಾಲುಹೊಡೆಯುತ್ತಿದೆ",
  cattle_milk_quantity: "ಹಾಲಿನ ಪ್ರಮಾಣ",
  cattle_fees: "ವೈದ್ಯಕೀಯ ವೆಚ್ಚ",
  cattle_medical_record: "ವೈದ್ಯಕೀಯ ದಾಖಲೆಗಳು",
  cattle_pregnancy_record: "ಗರ್ಭಧಾರಣಾ ದಾಖಲೆಗಳು",
  cattle_expense: "ವೆಚ್ಚ ನಿರ್ವಹಣೆ",
  cattle_stock: "ಸ್ಟಾಕ್ ನಿರ್ವಹಣೆ",
  cattle_usage: "ಬಳಕೆ ನಿರ್ವಹಣೆ",
  cattle_revenueHeading: "ದೈನಂದಿನ ನೋಂದಣಿ ವರದಿ",
  recent_registered_cattles: "ಇತ್ತೀಚಿನ ನೋಂದಾಯಿತ ಪಶುಧನ",
  cattle_registered: "ನೋಂದಾಯಿತ ಪಶುಧನ",
  cattle_add: "ಪಶುಧನ ಸೇರಿಸಿ",
  cattle_record_add: "ದಾಖಲೆ ಸೇರಿಸಿ",
  cattle_breed_add: "ಬೀಜ ಸೇರಿಸಿ",
  cattle_breed_edit: "ಬೀಜ ಸಂಪಾದಿಸಿ",
  cattle_medical_add: "ವೈದ್ಯಕೀಯ ದಾಖಲೆ ಸೇರಿಸಿ",
  cattle_pregnancy_report: "ಗರ್ಭಧಾರಣಾ ದಾಖಲೆಗಳು",
  cattle_pregnancy_report_add: "ಗರ್ಭಧಾರಣಾ ದಾಖಲೆ ಸೇರಿಸಿ",
  cattle_expense_add: "ವೆಚ್ಚ ಸೇರಿಸಿ",
  cattle_supplies_add: "ಪೂರೈಕೆಗಳನ್ನು ಸೇರಿಸಿ",
  cattle_items_add: "ಐಟಂಗಳು ಸೇರಿಸಿ",
  cattle_id: "ಪಶುಧನ ID",
  calf_id: "ಕಲ್ಫ್ ID",
  cattle_treatment_medicine: "ಚಿಕಿತ್ಸೆ/ಔಷಧ",
  cattle_dosage: "ಡೋಸ್",
  cattle_dr_name: "ಡಾ. ಹೆಸರು",
  cattle_purchase_price: "ಖರೀದಿ ಬೆಲೆ",
  cattle_symptoms: "ಲಕ್ಷಣಗಳು",
  cattle_usage_add: "ಬಳಕೆ ಸೇರಿಸಿ",
  cattles: "ಪಶುಧನಗಳು",
  cattles_feed: "ಆಹಾರ",
  cattles_medical: "ವೈದ್ಯಕೀಯ",
  cattles_death: "ಸಾವುಗಳು",
  cattles_machinery: "ಯಂತ್ರೋಪಕರಣಗಳು",
  cattle_conceiving_date: "ಗರ್ಭಧಾರಣಾ ದಿನಾಂಕ",
  cattle_pregnancy_date: "ಗರ್ಭಧಾರಣಾ ದಿನಾಂಕ",
  cattle_delivery_date: "ವಿತರಣಾ ದಿನಾಂಕ",
  cattle_pregnancy_status: "ಗರ್ಭಧಾರಣಾ ಸ್ಥಿತಿ",
  cattle_purpose: "ಉದ್ದೇಶ",
  cattle_itemId: "ಐಟಂ ID",
  cattle_itemName: "ಐಟಂ ಹೆಸರು",
  cattle_expense_quantity: "ಪ್ರಮಾಣ",
  cattle_expense_bill_invoice: "ಬಿಲ್/ಇನ್ವಾಯ್ಸ್",
  cattle_expense_bill_invoice_upload: "ಅಪ್‌ಲೋಡ್ ಮಾಡಿ",
  cattle_unit: "ಅಳತೆ",
  cattle_expense_unitType: "ಯುನಿಟ್ ಪ್ರಕಾರ",
  cattle_expense_current_quantity: "ಪ್ರಸ್ತುತ ಪ್ರಮಾಣ",
  cattle_expense_order_quantity: "ಆರ್ಡರ್ ಪ್ರಮಾಣ",
  cattle_last_update: "ಕೊನೆಯ ನವೀಕರಣ",
  cattle_tag_id: "ಟ್ಯಾಗ್ ID",
  cattle_date_purchased: "ಖರೀದಿಸಿದ ದಿನಾಂಕ",
  cattle_source: "ಮೂಲ",
  cattle_owner_name: "ಮಾಲೀಕನ/ಗೌರಕ್ಷಕನ ಹೆಸರು",
  cattle_owner_number: "ಮಾಲೀಕನ/ಗೌರಕ್ಷಕನ ಸಂಖ್ಯೆ",
  cattle_owner_image: "ಮಾಲೀಕನ/ಗೌರಕ್ಷಕನ ಚಿತ್ರ",
  cattle_cow_image: "ಹಸುವಿನ ಚಿತ್ರ",
  cattle_is_dead: "ಸತ್ತ",
  cattle_date_death: "ಸಾವು ದಿನಾಂಕ",
  cattle_death_reason: "ಸಾವು ಕಾರಣ",
  cattle_is_sold: "ಮಾರಾಟ",
  cattle_purchaser_name: "ಖರೀದಿದಾರನ ಹೆಸರು",
  cattle_purchaser_number: "ಖರೀದಿದಾರನ ಸಂಖ್ಯೆ",
  cattle_purchaser_id: "ಖರೀದಿದಾರನ ID",
  cattle_sold_date: "ಮಾರಾಟ ದಿನಾಂಕ",
  cattle_sold_price: "ಮಾರಾಟ ಬೆಲೆ",
  cattle_edit_medical: "ವೈದ್ಯಕೀಯ ದಾಖಲೆ ಸಂಪಾದಿಸಿ",
  cattle_edit_pregnancy: "ಗರ್ಭಧಾರಣಾ ದಾಖಲೆ ಸಂಪಾದಿಸಿ",
  cattle_edit_cattle: "ಪಶುಧನ ಸಂಪಾದಿಸಿ",
  cattle_edit_item: "ಐಟಂ ಸಂಪಾದಿಸಿ",
  cattle_edit_supplies: "ಪೂರೈಕೆಗಳನ್ನು ಸಂಪಾದಿಸಿ",
  cattle_edit_usage: "ಬಳಕೆ ಸಂಪಾದಿಸಿ",
  cattle_category_edit: "ವರ್ಗ ಸಂಪಾದಿಸಿ",
  cattle_category_add: "ವರ್ಗ ಸೇರಿಸಿ",

  // cattle validation messages
  cattle_itemID_required: "ಐಟಂ ID ಅಗತ್ಯವಿದೆ",
  cattle_id_required: "ಪಶುಧನ ID ಅಗತ್ಯವಿದೆ",
  cattle_pregnancy_status_required: "ಗರ್ಭಧಾರಣಾ ಸ್ಥಿತಿ ಅಗತ್ಯವಿದೆ",
  cattle_name_required: "ಹೆಸರು ಅಗತ್ಯವಿದೆ",
  cattle_order_quantity_required: "ಆರ್ಡರ್ ಪ್ರಮಾಣ ಅಗತ್ಯವಿದೆ",
  cattle_unit_required: "ಅಳತೆ ಅಗತ್ಯವಿದೆ",
  cattle_unit_type_required: "ಯುನಿಟ್ ಪ್ರಕಾರ ಅಗತ್ಯವಿದೆ",
  cattle_quantity_required: "ಪ್ರಮಾಣ ಅಗತ್ಯವಿದೆ",
  cattle_purpose_required: "ಉದ್ದೇಶ ಅಗತ್ಯವಿದೆ",
  cattle_treatment_medicine_required: "ಚಿಕಿತ್ಸೆ/ಔಷಧ ಅಗತ್ಯವಿದೆ",
  cattle_dosage_required: "ಡೋಸ್ ಅಗತ್ಯವಿದೆ",
  cattle_DrName_required: "ಡಾ. ಹೆಸರು ಅಗತ್ಯವಿದೆ",
  cattle_price_required: "ವೈದ್ಯಕೀಯ ವೆಚ್ಚ ಅಗತ್ಯವಿದೆ",
  cattle_symptoms_required: "ಲಕ್ಷಣಗಳು ಅಗತ್ಯವಿದೆ",
  cattle_tag_id_required: "ಟ್ಯಾಗ್ ID ಅಗತ್ಯವಿದೆ",
  cattle_type_required: "ವರ್ಗ ಅಗತ್ಯವಿದೆ",
  cattle_breed_required: "ಬೀಜ ಅಗತ್ಯವಿದೆ",
  cattle_age_required: "ವಯಸ್ಸು ಅಗತ್ಯವಿದೆ",
  cattle_purchase_price_required: "ಖರೀದಿ ಬೆಲೆ ಅಗತ್ಯವಿದೆ",
  cattle_source_required: "ಮೂಲ ಅಗತ್ಯವಿದೆ",
  cattle_owner_name_required: "ಮಾಲೀಕನ ಹೆಸರು ಅಗತ್ಯವಿದೆ",
  cattle_owner_id_required: "ಮಾಲೀಕನ ID ಅಗತ್ಯವಿದೆ",
  cattle_deathReason_required: "ಸಾವು ಕಾರಣ ಅಗತ್ಯವಿದೆ",
  cattle_purchaser_name_required: "ಖರೀದಿದಾರನ ಹೆಸರು ಅಗತ್ಯವಿದೆ",
  cattle_purchaser_id_required: "ಖರೀದಿದಾರನ ID ಅಗತ್ಯವಿದೆ",
  cattle_sold_price_required: "ಮಾರಾಟ ಬೆಲೆ ಅಗತ್ಯವಿದೆ",
  cattle_milk_quantity_required: "ಹಾಲಿನ ಪ್ರಮಾಣ ಅಗತ್ಯವಿದೆ",

  // all cattle management delete messages
  cattle_cattle_delete: "ಪಶುಧನ ಅಳಿಸು",
  cattle_cattle_sure: "ನೀವು ಖಚಿತವಾಗಿ ಈ ಪಶುಧನವನ್ನು ಅಳಿಸಲು ಬಯಸುತ್ತೀರಾ?",
  cattle_medical_delete: "ವೈದ್ಯಕೀಯ ಮಾಹಿತಿ ಅಳಿಸು",
  cattle_medical_sure: "ನೀವು ಖಚಿತವಾಗಿ ಈ ವೈದ್ಯಕೀಯ ವರದಿಯನ್ನು ಅಳಿಸಲು ಬಯಸುತ್ತೀರಾ?",
  cattle_pregnancy_delete: "ಗರ್ಭಧಾರಣಾ ವರದಿ ಅಳಿಸು",
  cattle_pregnancy_sure:
    "ನೀವು ಖಚಿತವಾಗಿ ಈ ಗರ್ಭಧಾರಣಾ ವರದಿಯನ್ನು ಅಳಿಸಲು ಬಯಸುತ್ತೀರಾ?",
  cattle_supplies_delete: "ಪೂರೈಕೆಗಳನ್ನು ಅಳಿಸು",
  cattle_supplies_sure: "ನೀವು ಖಚಿತವಾಗಿ ಈ ಪೂರೈಕೆಗಳನ್ನು ಅಳಿಸಲು ಬಯಸುತ್ತೀರಾ?",

  cattle_usage_delete: "ಬಳಕೆ ಅಳಿಸು",
  cattle_usage_sure: "ನೀವು ಖಚಿತವಾಗಿ ಈ ಬಳಕೆಯನ್ನು ಅಳಿಸಲು ಬಯಸುತ್ತೀರಾ?",

  cattle_item_delete: "ಐಟಂ ಅಳಿಸು",
  cattle_item_sure: "ನೀವು ಖಚಿತವಾಗಿ ಈ ಐಟಂ ಅನ್ನು ಅಳಿಸಲು ಬಯಸುತ್ತೀರಾ?",
  cattle_breed_delete: "ಬೀಜ ಅಳಿಸು",
  cattle_breed_sure: "ನೀವು ಖಚಿತವಾಗಿ ಈ ಬೀಜವನ್ನು ಅಳಿಸಲು ಬಯಸುತ್ತೀರಾ?",

  // All cattle search variable
  search_cattles: "ಪಶುಧನID, ಪ್ರಕಾರ, ಬೀಜ ಮೂಲಕ ಹುಡುಕಿ",
  search_cattles_medical: "ಪಶುಧನ ವೈದ್ಯಕೀಯ ಮಾಹಿತಿ ಹುಡುಕಿ",
  search_cattles_pregnancy: "ಪಶುಧನ ಗರ್ಭಧಾರಣಾ ವರದಿ ಹುಡುಕಿ",
  search_cattles_stock: "ಸ್ಟಾಕ್ ಹುಡುಕಿ",
  search_cattles_supplies: "ಐಟಂ ಪೂರೈಕೆಗಳನ್ನು ಹುಡುಕಿ",
  search_cattles_usage: "ಐಟಂ ಬಳಕೆಗಳನ್ನು ಹುಡುಕಿ",
  search_cattles_items: "ಪಶುಧನ ಐಟಂಗಳನ್ನು ಹುಡುಕಿ",
  // All static content of Notices

  notices_AddNotice: "ಅವಿಷ್ಕಾರ ಸೇರಿಸಿ",
  notices_latest_Notice: "ಅವಿಷ್ಕಾರಗಳು",
  add_image: "ಚಿತ್ರ ಸೇರಿಸಿ",
  image_size_suggestion:
    "(ದಯವಿಟ್ಟು 450px*350px ಗಾತ್ರದ ಚಿತ್ರವನ್ನು ಅಪ್‌ಲೋಡ್ ಮಾಡಿ)",
  notices_title_required: "ಶೀರ್ಷಿಕೆ ಅಗತ್ಯವಿದೆ",
  notices_desc_required: "ವಿವರಣೆ ಅಗತ್ಯವಿದೆ",
  notices_select_dropDown: "ಅವಿಷ್ಕಾರ ಆಯ್ಕೆಮಾಡಿ",
  notices_EditNotice: "ಅವಿಷ್ಕಾರ ಸಂಪಾದಿಸಿ",

  notices_delete: "ಅವಿಷ್ಕಾರ ಅಳಿಸು",
  notices_sure: "ನೀವು ಖಚಿತವಾಗಿ ಈ ಅವಿಷ್ಕಾರವನ್ನು ಶಾಶ್ವತವಾಗಿ ಅಳಿಸಲು ಬಯಸುತ್ತೀರಾ?",
  notices_not_found: "ಅವಿಷ್ಕಾರಗಳು ಕಾಣಲಿಲ್ಲ",
  notices_not_click_add_notices:
    "ಹೊಸ ಅವಿಷ್ಕಾರವನ್ನು ಸೇರಿಸಲು, 'ಅವಿಷ್ಕಾರ ಸೇರಿಸಿ' ಕ್ಲಿಕ್ ಮಾಡಿ.",

  // All static content of Categories
  only_letters: "ಉಪ ವರ್ಗವು ಅಕ್ಷರಗಳನ್ನಷ್ಟೇ ಒಳಗೊಂಡಿರಬೇಕು",
  categories_AddCategory: "ಉಪ ವರ್ಗ ಸೇರಿಸಿ",
  categories_serial_number: "ಕ್ರಮ ಸಂಖ್ಯೆ",
  categories_master_category: "ಮಾಸ್ಟರ್ ವರ್ಗ",
  categories_sub_category: "ಉಪ ವರ್ಗ",
  categories_category_required: "ಮಾಸ್ಟರ್ ವರ್ಗ ಅಗತ್ಯವಿದೆ",
  categories_sub_category_required: "ಉಪ ವರ್ಗ ಅಗತ್ಯವಿದೆ",
  categories_latest_Category: "ವರ್ಗಗಳು",
  categories_title_required: "ವರ್ಗದ ಹೆಸರು ಅಗತ್ಯವಿದೆ",
  categories_EditCategory: "ವರ್ಗ ಸಂಪಾದಿಸಿ",
  categories_select_category: "ವರ್ಗ ಆಯ್ಕೆಮಾಡಿ",
  categories_select_amount: "ಮೊತ್ತವನ್ನು ನಮೂದಿಸಿ",
  category_select_sub_category: "ಉಪ ವರ್ಗ ಆಯ್ಕೆಮಾಡಿ",
  categories_select_user_name: "ಬಳಕೆದಾರನ ಹೆಸರು ಆಯ್ಕೆಮಾಡಿ",
  category_select: "ಆಯ್ಕೆಮಾಡಿ",
  categories_select_master_category: "ಮಾಸ್ಟರ್ ವರ್ಗ ಆಯ್ಕೆಮಾಡಿ",
  donar_name_required: "ದಾತನ ಹೆಸರು ಅಗತ್ಯವಿದೆ",
  created_by_required: "ನಿರ್ಮಿಸಿದವರು ಅಗತ್ಯವಿದೆ",
  user_select_required: "ಬಳಕೆದಾರನ ಹೆಸರು ಅಗತ್ಯವಿದೆ",
  masterCategory_required: "ವರ್ಗ ಅಗತ್ಯವಿದೆ",
  subCategory_required: "ದಯವಿಟ್ಟು ಉಪ ವರ್ಗ ಆಯ್ಕೆಮಾಡಿ",
  commitmentID_required: "ದಯವಿಟ್ಟು ಬದ್ಧತಾ ID ಆಯ್ಕೆಮಾಡಿ",
  amount_required: "ಮೊತ್ತ ಅಗತ್ಯವಿದೆ",

  category_delete: "ವರ್ಗ ಅಳಿಸು",
  category_sure: "ನೀವು ಖಚಿತವಾಗಿ ಈ ವರ್ಗವನ್ನು ಶಾಶ್ವತವಾಗಿ ಅಳಿಸಲು ಬಯಸುತ್ತೀರಾ?",
  category_not_found: "ವರ್ಗಗಳು ಲಭ್ಯವಿಲ್ಲ",
  category_not_click_add_category:
    "ಹೊಸ ವರ್ಗವನ್ನು ಪರಿಚಯಿಸಲು, 'ವರ್ಗ ಸೇರಿಸಿ' ಕ್ಲಿಕ್ ಮಾಡಿ.",

  // All static content of Users
  users_AddUser: "ಉಪ ನಿರ್ವಾಹಕ ಸೇರಿಸಿ",
  users_latest_User: "ಉಪ ನಿರ್ವಾಹಕರು",
  users_title_required: "ಬಳಕೆದಾರನ ಹೆಸರು ಅಗತ್ಯವಿದೆ",
  user_only_letters: "ಬಳಕೆದಾರನ ಹೆಸರು ಅಕ್ಷರಗಳನ್ನು ಮಾತ್ರ ಹೊಂದಿರಬೇಕು",
  users_mobile_required: "ಮೊಬೈಲ್ ನಂಬರ್ ಅಗತ್ಯವಿದೆ",
  mobile_number_limit: "ಮೊಬೈಲ್ ನಂಬರ್ 10 ಅಂಕಿಗಳಾಗಿರಬೇಕು",
  users_email_required: "ಇಮೇಲ್ ಅಗತ್ಯವಿದೆ",
  users_not_found: "ಉಪ ನಿರ್ವಾಹಕರು ಕಾಣಲಿಲ್ಲ",
  users_not_click_add_users:
    "ಹೊಸ ಉಪ ನಿರ್ವಾಹಕ ಬಳಕೆದಾರರನ್ನು ಸೇರಿಸಲು, 'ಉಪ ನಿರ್ವಾಹಕ ಸೇರಿಸಿ' ಕ್ಲಿಕ್ ಮಾಡಿ.",

  // All static content of Internal Expense
  expenses_AddExpenses: "ಖರ್ಚು ಸೇರಿಸಿ",
  price_per_item: "ಪ್ರತಿ ಐಟಂ ಬೆಲೆ",
  expense_type_required: "ಖರ್ಚು ಪ್ರಕಾರ ಅಗತ್ಯವಿದೆ",
  expenses_EditExpenses: "ಖರ್ಚು ಸಂಪಾದಿಸಿ",
  expenses_Date: "ದಿನಾಂಕ",
  expence_description: "ವಿವರಣೆ",
  expenses_latest_Expenses: "ಎಲ್ಲಾ ಖರ್ಚುಗಳು",
  expenses_title_required: "ಶೀರ್ಷಿಕೆ ಅಗತ್ಯವಿದೆ",
  expenses_mobile_required: "ಮೊಬೈಲ್ ನಂಬರ್ ಅಗತ್ಯವಿದೆ",
  expenses_email_required: "ಇಮೇಲ್ ಅಗತ್ಯವಿದೆ",
  added_by: "ಸೇರಿಸಿದವರು",
  expenses_desc_required: "ವಿವರಣೆ ಅಗತ್ಯವಿದೆ",
  expence_amount_required: "ಮೊತ್ತ ಅಗತ್ಯವಿದೆ",

  expence_delete: "ಖರ್ಚು ಅಳಿಸು",
  expence_sure: "ನೀವು ಖಚಿತವಾಗಿ ಈ ಖರ್ಚನ್ನು ಶಾಶ್ವತವಾಗಿ ಅಳಿಸಲು ಬಯಸುತ್ತೀರಾ?",
  expence_not_found: "ಖರ್ಚುಗಳು ಕಾಣಲಿಲ್ಲ",
  expence_not_click_add_expence:
    "ಹೊಸ ಖರ್ಚುಗಳನ್ನು ದಾಖಲಿಸಲು, 'ಖರ್ಚು ಸೇರಿಸಿ' ಕ್ಲಿಕ್ ಮಾಡಿ.",

  // All static content of Financial Report

  report_AddReport: "ಹಣಕಾಸು ವರದಿಗಳು",
  report_expences: "ಖರ್ಚುಗಳು",
  remarks_financial_donationBox: "ಟಿಪ್ಪಣಿಗಳು",
  report_commitment: "ಬದ್ಧತೆ",
  reports_not_found: "ವರದಿ & ವ್ಯಾಜ್ಯ ಕಾಣಲಿಲ್ಲ",
  report_donation_box: "ದೇಣಿಗೆ ಪೆಟ್ಟಿಗೆ ಸಂಗ್ರಹ",
  finance_report_no_found: "ಆಯ್ಕೆಮಾಡಿದ ಸಮಯಕ್ಕಾಗಿ ಯಾವುದೇ ಹಣಕಾಸು ವರದಿ ಲಭ್ಯವಿಲ್ಲ",
  financial_not_found: "ಯಾವುದೇ ಹಣಕಾಸು ಲಭ್ಯವಿಲ್ಲ",

  // All static content of Donation
  donation_Donation: "ದೇಣಿಗೆ",
  start_Time: "ಪ್ರಾರಂಭದ ಸಮಯ",
  end_Time: "ಅಂತ್ಯದ ಸಮಯ",
  is_government: "ಸರಕಾರದ ದಾತನ",
  donation_Adddonation: "ದೇಣಿಗೆ ಸೇರಿಸಿ",
  original_amount: "ಮೂಲ ಮೊತ್ತ",
  estimate_amount: "ಅಂದಾಜು ಮೊತ್ತ",
  donation_select_date_time: "ದಿನಾಂಕ ಆಯ್ಕೆಮಾಡಿ",
  donation_select_date: "ದಿನಾಂಕ ಆಯ್ಕೆಮಾಡಿ",
  donation_donar_name_only_letters: "ದಾತನ ಹೆಸರು ಅಕ್ಷರಗಳನ್ನು ಮಾತ್ರ ಹೊಂದಿರಬೇಕು",
  add_user_donation: "ಯಾವುದೇ ಬಳಕೆದಾರ ಕಂಡುಬಂದಿಲ್ಲ, ಇಲ್ಲಿ ಕ್ಲಿಕ್ ಮಾಡಿ",
  donation_delete: "ದೇಣಿಗೆ ಅಳಿಸು",
  donation_sure: "ನೀವು ಖಚಿತವಾಗಿ ಈ ದೇಣಿಗೆಯನ್ನು ಶಾಶ್ವತವಾಗಿ ಅಳಿಸಲು ಬಯಸುತ್ತೀರಾ?",
  donation_not_found: "ಯಾವುದೇ ದೇಣಿಗೆ ಕಂಡುಬಂದಿಲ್ಲ",
  add_user: "ಬಳಕೆದಾರ ಸೇರಿಸಿ",
  donation_paid_not_found: "ಪಾವತಿಸಿದ ದೇಣಿಗೆಗಳು ಕಂಡುಬಂದಿಲ್ಲ",
  donation_not_click_add_donation:
    'ತೆರೆ ಮೇಲೆ ದೇಣಿಗೆಯನ್ನು ಸೇರಿಸಲು "ದೇಣಿಗೆ ಸೇರಿಸಿ" ಕ್ಲಿಕ್ ಮಾಡಿ',

  // All static content of Donation Box
  DonationBox_total_collection: "ಒಟ್ಟು ಸಂಗ್ರಹ:",
  DonationBox_DonationBox: "ದೇಣಿಗೆ ಪೆಟ್ಟಿಗೆ ಸಂಗ್ರಹ",
  DonationBox_AddCollectionBox: "ಸಂಗ್ರಹ ಸೇರಿಸಿ",
  DonationBox_EditCollectionBox: "ಸಂಗ್ರಹ ಸಂಪಾದಿಸಿ",
  created_by: "ಸೃಷ್ಟಿಸಿದವರು",
  edit_collection: "ಸಂಗ್ರಹ ಸಂಪಾದಿಸಿ",
  enter_price_manually: "ಮೊತ್ತವನ್ನು ನಮೂದಿಸಿ",
  viewLogs: "ಲಾಗ್ಗಳನ್ನು ವೀಕ್ಷಿಸಿ",
  logs: "ಲಾಗ್ಗಳು",
  add_remarks: "ಟಿಪ್ಪಣಿಗಳನ್ನು ಸೇರಿಸಿ",
  add_amount: "ಮೊತ್ತ ಸೇರಿಸಿ",
  DonationBox_AddDonationBox: "ದೇಣಿಗೆ ಪೆಟ್ಟಿಗೆ ಸೇರಿಸಿ",
  donation_box_desc_required: "ವಿವರಣೆ ಅಗತ್ಯವಿದೆ",
  donation_box_amount_required: "ಮೊತ್ತ ಅಗತ್ಯವಿದೆ",

  logData_editedBy: "ಸಂಪಾದಿಸಿದವರು",
  logData_createdBy: "ಸೃಷ್ಟಿಸಿದವರು",

  logData_timeDate: "ಸಮಯ & ದಿನಾಂಕ",
  logData_createdAmount: "ಸೃಷ್ಟಿಸಿದ ಮೊತ್ತ",
  logData_editedAmount: "ಸಂಪಾದಿಸಿದ ಮೊತ್ತ",

  donation_box_not_found: "ದೇಣಿಗೆ ಪೆಟ್ಟಿಗೆಗಳು ಕಾಣಲಿಲ್ಲ",
  donation_box_not_click_add_donation_box:
    "ಹೊಸ ದೇಣಿಗೆ ಪೆಟ್ಟಿಗೆಯನ್ನು ಪರಿಚಯಿಸಲು, 'ಸಂಗ್ರಹ ಸೇರಿಸಿ' ಕ್ಲಿಕ್ ಮಾಡಿ",

  // All Commitment Data
  notify_user: "ಬಳಕೆದಾರನಿಗೆ ತಿಳಿಸಿ",
  notify_user_content:
    "ಬಳಕೆದಾರನನ್ನು ನ್ಯುಡ್ಜ್ ಮಾಡಲು ದಯವಿಟ್ಟು ಚಕ್ಬಾಕ್ಸ್ ಪರಿಶೀಲಿಸಿ",
  commitment_Username: "ಬಳಕೆದಾರನ ಹೆಸರು",
  add_commitment: "ಬದ್ಧತೆ ಸೇರಿಸಿ",
  edit_commitment: "ಬದ್ಧತೆ ಸಂಪಾದಿಸಿ",
  commitment_end_Date: "ಅಂತ್ಯದ ದಿನಾಂಕ",
  commitment_Amount_Due: "ಬಾಕಿ ಮೊತ್ತ",
  commitment_select_commitment_id: "ಬದ್ಧತಾ ID ಆಯ್ಕೆಮಾಡಿ",
  commitment_select_start_date: "ಪ್ರಾರಂಭದ ದಿನಾಂಕ ಆಯ್ಕೆಮಾಡಿ",
  commitment_select_end_date: "ಅಂತ್ಯದ ದಿನಾಂಕ ಆಯ್ಕೆಮಾಡಿ",

  commitment_delete: "ಬದ್ಧತೆ ಅಳಿಸು",
  commitment_sure: "ನೀವು ಖಚಿತವಾಗಿ ಈ ಬದ್ಧತೆಯನ್ನು ಶಾಶ್ವತವಾಗಿ ಅಳಿಸಲು ಬಯಸುತ್ತೀರಾ?",
  commitment_not_found: "ಬದ್ಧತೆಗಳು ಕಾಣಲಿಲ್ಲ",
  commitment_not_click_add_commitment:
    "ಹೊಸ ಬದ್ಧತೆಯನ್ನು ಮಾಡಲು, 'ಬದ್ಧತೆ ಸೇರಿಸಿ' ಕ್ಲಿಕ್ ಮಾಡಿ",
  commitment_complete: "ಪೂರ್ಣಗೊಂಡಿದೆ",
  commitment_partial: "ಭಾಗಶಃ",

  // All subscribed user static data
  subscribed_user_add_user: "ಚಂದಾದಾರರನ್ನು ಸೇರಿಸಿ",
  subscribed_user_name: "ಬಳಕೆದಾರನ ಹೆಸರು",
  subscribed_user_pan_cardDetail: "ಪ್ಯಾನ್ ಕಾರ್ಡ್ ವಿವರಗಳು",
  subscribed_user_date_birth: "ಹುಟ್ಟುಹಬ್ಬದ ದಿನಾಂಕ",
  subscribed_user_email: "ಇಮೇಲ್",
  subscribed_user_address: "ವಿಳಾಸ",
  user_name: "ಬಳಕೆದಾರನ ಹೆಸರು",
  subscribed_not_found: "ಯಾವುದೇ ಚಂದಾದಾರರು ಕಂಡುಬಂದಿಲ್ಲ",
  subscribed_not_click_add:
    'ತೆರೆ ಮೇಲೆ ಚಂದಾದಾರರನ್ನು ಸೇರಿಸಲು "ಚಂದಾದಾರರನ್ನು ಸೇರಿಸಿ" ಕ್ಲಿಕ್ ಮಾಡಿ',

  // All report & dispute static data
  report_report_against: "ಹೆಸರು",
  report_mobile: "ಮೊಬೈಲ್ ನಂಬರ್",
  export_report: "ವರದಿ ರಫ್ತು ಮಾಡಿ",
  report_Transaction_IDs: "ವ್ಯವಹಾರ IDs",
  report_panding: "ಬಾಕಿ",
  report_re_solved: "ಪುನಃ ಪರಿಹರಿಸಲಾಗಿದೆ",
  report_dispute_not_found: "ವರದಿ & ವ್ಯಾಜ್ಯಗಳು ಕಾಣಲಿಲ್ಲ",

  // All notification static data
  notifications: "ಅಧಿಸೂಚನೆಗಳು",
  notifications_Notify_Users: "ಬಳಕೆದಾರರನ್ನು ತಿಳಿಸಿ",
  notifications_not_found: "ಯಾವುದೇ ಅಧಿಸೂಚನೆಗಳು ಕಂಡುಬಂದಿಲ್ಲ",

  // All user Profile static Data
  about_trust: "ನಮ್ಮ ಬಗ್ಗೆ",
  trust_address: "ವಿಳಾಸ",
  trust_user: "ಬಳಕೆದಾರನ ಬಗ್ಗೆ",
  trust_location: "ಸ್ಥಳ",
  trust_facility: "ಸೌಲಭ್ಯಗಳು",
  trust_facebookLink: "ಫೇಸ್ಬುಕ್ ಲಿಂಕ್",
  trust_facebookLink_required: "ಫೇಸ್ಬುಕ್ ಲಿಂಕ್ ಅಗತ್ಯವಿದೆ",
  trust_contact_number_required: "ಸಂಪರ್ಕ ಸಂಖ್ಯೆಯ ಅಗತ್ಯವಿದೆ",
  contact_number: "ಸಂಪರ್ಕ ಸಂಖ್ಯೆ",
  trust_image_certificate: "ಚಿತ್ರಗಳು ಮತ್ತು ಪ್ರಮಾಣಪತ್ರಗಳು",
  location: "ಸ್ಥಳ",
  trust_prefenses: "ಟ್ರಸ್ಟ್ ಆದ್ಯತೆಗಳು",
  trust_prefenses_required: "ಟ್ರಸ್ಟ್ ಆದ್ಯತೆಗಳು ಅಗತ್ಯವಿದೆ",
  latitude: "ಅಕ್ಷಾಂಶ",
  longitude: "ರೆಖಾಂಶ",
  userProfile: "ಪ್ರೊಫೈಲ್",
  user_editUser: "ಬಳಕೆದಾರನನ್ನು ಸಂಪಾದಿಸಿ",
  user_userRoleRequired: "ಬಳಕೆದಾರನ ಪಾತ್ರ ಅಗತ್ಯವಿದೆ",
  minimum_one_role_required: "ಕನಿಷ್ಠ ಒಂದು ಬಳಕೆದಾರನ ಪಾತ್ರ ಆಯ್ಕೆಮಾಡಬೇಕು",
  password_required: "ಪಾಸ್ವರ್ಡ್ ಅಗತ್ಯವಿದೆ",
  userProfile_phone_number: "ದೂರವಾಣಿ ಸಂಖ್ಯೆ",
  trust_trustType: "ಟ್ರಸ್ಟ್ ಪ್ರಕಾರ",
  about_trusts: "ನಮ್ಮ ಬಗ್ಗೆ",
  userProfile_name: "ಹೆಸರು",
  trust_type_required: "ಟ್ರಸ್ಟ್ ಪ್ರಕಾರ ಅಗತ್ಯವಿದೆ",
  trust_about_required: "ಟ್ರಸ್ಟ್ ಬಗ್ಗೆ ಅಗತ್ಯವಿದೆ",
  State: "ರಾಜ್ಯ",
  City: "ನಗರ",
  events_state_required: "ರಾಜ್ಯ ಅಗತ್ಯವಿದೆ",
  events_city_required: "ನಗರ ಅಗತ್ಯವಿದೆ",
  events_location_required: "ಸ್ಥಳ ಅಗತ್ಯವಿದೆ",
  facilities: "ಟ್ರಸ್ಟ್ ಸೌಲಭ್ಯಗಳು",
  userProfile_email_id: "ಇಮೇಲ್",
  userProfile_temple: "ದೇವಸ್ಥಾನ",
  latitude: "ಅಕ್ಷಾಂಶ",
  longitude: "ರೆಖಾಂಶ",
  longitude_required: "ರೆಖಾಂಶ ಅಗತ್ಯವಿದೆ",
  latitude_required: "ಅಕ್ಷಾಂಶ ಅಗತ್ಯವಿದೆ",
  user_password: "ಪಾಸ್ವರ್ಡ್",
  user_password_old: "ಹಳೆಯ ಪಾಸ್ವರ್ಡ್",
  user_password_new: "ಹೊಸ ಪಾಸ್ವರ್ಡ್",
  edit_image: "ಸಂಪಾದಿಸಿ",
  user_password_confirm: "ಪಾಸ್ವರ್ಡ್ ದೃಢೀಕರಿಸಿ",
  userProfile_trust_type: "ಟ್ರಸ್ಟ್ ಪ್ರಕಾರ",
  userProfile_doc_certificate: "ಡಾಕ್ಯುಮೆಂಟ್/ಪ್ರಮಾಣಪತ್ರ",
  userProfile_facilities: "ಸೌಲಭ್ಯಗಳು",
  userProfile_punyarjak: "ಪುಣ್ಯರ್ಜಕ",
  userProfile_temple_upload_doc: "ನಿಮ್ಮ ದೇವಸ್ಥಾನದ ಡಾಕ್ಯುಮೆಂಟ್ ಅಪ್ಲೋಡ್ ಮಾಡಿ",
  user_userRole: "ಬಳಕೆದಾರನ ಪಾತ್ರಗಳು",
  images: "ಚಿತ್ರಗಳು",
  browse: "ವೀಕ್ಷಿಸಿ",
  update_profile: "ನವೀಕರಿಸಿ",
  name_required: "ಹೆಸರು ಅಗತ್ಯವಿದೆ",
  start_time: "ಪ್ರಾರಂಭದ ಸಮಯ",
  start_time_required: "ಪ್ರಾರಂಭದ ಸಮಯ ಅಗತ್ಯವಿದೆ",
  end_time: "ಅಂತ್ಯದ ಸಮಯ",
  end_time_required: "ಅಂತ್ಯದ ಸಮಯ ಅಗತ್ಯವಿದೆ",

  email_invalid: "ಅಮಾನ್ಯ ಇಮೇಲ್",
  email_required: "ಇಮೇಲ್ ಅಗತ್ಯವಿದೆ",
  number_type: "ಇದು ದೂರವಾಣಿ ಸಂಖ್ಯೆಯಂತೆ ಕಾಣುತ್ತಿಲ್ಲ",
  cant_start_minus: "ದೂರವಾಣಿ ಸಂಖ್ಯೆ ಮೈನಸ್ ಚಿಹ್ನೆಯಿಂದ ಪ್ರಾರಂಭಿಸದಿರಬೇಕು",
  number_in_point: "ದೂರವಾಣಿ ಸಂಖ್ಯೆಯಲ್ಲಿ ಡೆಸಿಮಲ್ ಬಿಂದು ಇರಲು ಸಾಧ್ಯವಿಲ್ಲ",
  number_required: "ದೂರವಾಣಿ ಸಂಖ್ಯೆ ಅಗತ್ಯವಿದೆ",
  address_required: "ವಿಳಾಸ ಅಗತ್ಯವಿದೆ",
  Temple_name_required: "ದೇವಸ್ಥಾನದ ಹೆಸರು ಅಗತ್ಯವಿದೆ",
  doc_required: "ದೇವಸ್ಥಾನದ ಡಾಕ್ಯುಮೆಂಟ್ ಅಗತ್ಯವಿದೆ",

  // punyarjak
  punyarjak: "ಪುಣ್ಯರ್ಜಕ",
  add_punyarjak: "ಪುಣ್ಯರ್ಜಕ ಸೇರಿಸಿ",
  img_required: "ಚಿತ್ರ ಅಗತ್ಯವಿದೆ",
  edit_punyarjak: "ಪುಣ್ಯರ್ಜಕ ಸಂಪಾದಿಸಿ",
  name: "ಹೆಸರು",
  description: "ವಿವರಣೆ",
  punyarjak_desc_required: "ವಿವರಣೆ ಅಗತ್ಯವಿದೆ",
  punyarjak_delete: "ಪುಣ್ಯರ್ಜಕ ಅಳಿಸು",
  username: "ಬಳಕೆದಾರನ ಹೆಸರು ಅಕ್ಷರಗಳನ್ನು ಮಾತ್ರ ಹೊಂದಿರಬೇಕು",
  punyarjak_sure:
    "ನೀವು ಖಚಿತವಾಗಿ ಈ ಪುಣ್ಯರ್ಜಕ ಅನ್ನು ಶಾಶ್ವತವಾಗಿ ಅಳಿಸಲು ಬಯಸುತ್ತೀರಾ?",
  punyarjak_not_found: "ಪುಣ್ಯರ್ಜಕ ಬಳಕೆದಾರರು ಕಾಣಲಿಲ್ಲ",
  punyarjak_not_click_add:
    "ಹೊಸ ಪುಣ್ಯರ್ಜಕ ಬಳಕೆದಾರರನ್ನು ಸೇರಿಸಲು, 'ಪುಣ್ಯರ್ಜಕ ಸೇರಿಸಿ' ಕ್ಲಿಕ್ ಮಾಡಿ",

  // all place holder images
  placeHolder_donar_name: "ದಾತನ ಹೆಸರನ್ನು ನಮೂದಿಸಿ",
  placeHolder_price_per_item: "ಪ್ರತಿ ಐಟಂ ಬೆಲೆಯನ್ನು ನಮೂದಿಸಿ",
  placeHolder_mobile_number: "ಮೊಬೈಲ್ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ",
  placeHolder_phone_number: "ಸಂಪರ್ಕ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ",
  placeHolder_title: "ಶೀರ್ಷಿಕೆಯನ್ನು ನಮೂದಿಸಿ",
  placeHolder_description: "ವಿವರಣೆಯನ್ನು ನಮೂದಿಸಿ",
  placeHolder_tags: "ಟ್ಯಾಗ್‌ಗಳನ್ನು ನಮೂದಿಸಿ",
  placeHolder_name: "ಹೆಸರನ್ನು ನಮೂದಿಸಿ",
  placeHolder_user_name: "ಬಳಕೆದಾರನ ಹೆಸರನ್ನು ನಮೂದಿಸಿ",
  placeHolder_email: "ಇಮೇಲ್ ನಮೂದಿಸಿ",
  placeHolder_password: "ಪಾಸ್ವರ್ಡ್ ನಮೂದಿಸಿ",
  placeHolder_sub_category: "ಉಪ ವರ್ಗವನ್ನು ನಮೂದಿಸಿ",
  placeHolder_city: "ನಗರವನ್ನು ನಮೂದಿಸಿ",
  placeHolder_state: "ರಾಜ್ಯವನ್ನು ನಮೂದಿಸಿ",
  placeHolder_location: "ಸ್ಥಳವನ್ನು ನಮೂದಿಸಿ",
  placeHolder_trust_facebook: "ಫೇಸ್ಬುಕ್ ಲಿಂಕ್ ಅನ್ನು ನಮೂದಿಸಿ",
  placeHolder_trust_address: "ವಿಳಾಸವನ್ನು ನಮೂದಿಸಿ",
  placeHolder_cattle_id: "ಪಶುಧನ ID ಆಯ್ಕೆಮಾಡಿ",
  placeHolder_calf_id: "ಕಲ್ಫ್ ID ಆಯ್ಕೆಮಾಡಿ",
  placeHolder_cattle_treatment_medicine: "ಚಿಕಿತ್ಸೆ/ಔಷಧವನ್ನು ಇಲ್ಲಿ ನಮೂದಿಸಿ",
  placeHolder_cattle_dosage: "ಡೋಸ್ ಅನ್ನು ಇಲ್ಲಿ ನಮೂದಿಸಿ",
  placeHolder_cattle_dr_name: "ಡಾ. ಹೆಸರನ್ನು ನಮೂದಿಸಿ",
  placeHolder_cattle_purchase_price: "ಬೆಲೆಯನ್ನು ಇಲ್ಲಿ ನಮೂದಿಸಿ",
  placeHolder_cattle_fees: "ವೈದ್ಯಕೀಯ ವೆಚ್ಚವನ್ನು ನಮೂದಿಸಿ",
  placeHolder_cattle_symptoms: "ಲಕ್ಷಣಗಳನ್ನು ನಮೂದಿಸಿ",
  placeHolder_cattle_pregnancy_status: "ಗರ್ಭಧಾರಣಾ ಸ್ಥಿತಿಯನ್ನು ನಮೂದಿಸಿ",
  placeHolder_cattle_itemId: "ಐಟಂ ID ಆಯ್ಕೆಮಾಡಿ",
  placeHolder_cattle_item_name: "ಐಟಂ ಹೆಸರನ್ನು ಆಯ್ಕೆಮಾಡಿ",
  placeHolder_cattle_expense_quantity: "ಪ್ರಮಾಣವನ್ನು ಇಲ್ಲಿ ನಮೂದಿಸಿ",
  placeHolder_cattle_unit: "ಪ್ರಮಾಣದ ಯುನಿಟ್ ಆಯ್ಕೆಮಾಡಿ",
  placeHolder_cattle_expense_unitType: "ಯುನಿಟ್ ಪ್ರಕಾರವನ್ನು ಆಯ್ಕೆಮಾಡಿ",
  placeHolder_cattle_purpose: "ಉದ್ದೇಶವನ್ನು ನಮೂದಿಸಿ",
  placeHolder_cattle_item_id: "ಐಟಂ ಹೆಸರನ್ನು ಇಲ್ಲಿ ನಮೂದಿಸಿ",
  placeHolder_cattle_expense_current_quantity: "ಪ್ರಸ್ತುತ ಪ್ರಮಾಣವನ್ನು ನಮೂದಿಸಿ",
  placeHolder_cattle_expense_order_quantity: "ಆರ್ಡರ್ ಪ್ರಮಾಣವನ್ನು ನಮೂದಿಸಿ",
  placeholder_cattle_type: "ಪಶುಧನ ವರ್ಗವನ್ನು ಆಯ್ಕೆಮಾಡಿ",
  placeHolder_cattle_breed: "ಪಶುಧನ ಬೀಜವನ್ನು ಆಯ್ಕೆಮಾಡಿ",
  placeHolder_cattle_age: "ವಯಸ್ಸನ್ನು ಇಲ್ಲಿ ನಮೂದಿಸಿ",
  placeHolder_cattle_source: "ಮೂಲವನ್ನು ಆಯ್ಕೆಮಾಡಿ",
  placeHolder_cattle_owner_name: "ಮಾಲೀಕನ/ಗೌರಕ್ಷಕನ ಹೆಸರನ್ನು ನಮೂದಿಸಿ",
  placeHolder_cattle_owner_Id: "ಮಾಲೀಕನ/ಗೌರಕ್ಷಕನ ID ನಮೂದಿಸಿ",
  placeHolder_cattle_tag_id: "ಟ್ಯಾಗ್ ID ನಮೂದಿಸಿ",
  placeholder_cattle_mother_id: "ತಾಯಿಯ ಟ್ಯಾಗ್ ID ಆಯ್ಕೆಮಾಡಿ ಅಥವಾ ನಮೂದಿಸಿ",
  placeHolder_cattle_owner_number: "ಮಾಲೀಕನ/ಗೌರಕ್ಷಕನ ಹೆಸರನ್ನು ನಮೂದಿಸಿ",
  placeHolder_cattle_death_reason: "ಸಾವಿನ ಕಾರಣವನ್ನು ಇಲ್ಲಿ ನಮೂದಿಸಿ",
  placeHolder_cattle_purchaser_name: "ಖರೀದಿದಾರನ ಹೆಸರನ್ನು ನಮೂದಿಸಿ",
  placeHolder_cattle_purchaser_number: "ಖರೀದಿದಾರನ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ",
  placeHolder_cattle_purchaser_id: "ಖರೀದಿದಾರನ ID ನಮೂದಿಸಿ",
  placeHolder_cattle_sold_price: "ಮಾರಾಟ ಬೆಲೆಯನ್ನು ನಮೂದಿಸಿ",
  placeHolder_cattle_milk_quantity: "ಹಾಲಿನ ಪ್ರಮಾಣವನ್ನು ಲೀಟರ್‌ಗಳಲ್ಲಿ ನಮೂದಿಸಿ",
  placeHolder_expense_type: "ಖರ್ಚು ಪ್ರಕಾರವನ್ನು ಆಯ್ಕೆಮಾಡಿ",

  // All search static keys
  search_notification: "ಎಲ್ಲಾ ಅಧಿಸೂಚನೆಗಳನ್ನು ಹುಡುಕಿ",
  search_subscribe_user: "ಎಲ್ಲಾ ಚಂದಾದಾರರನ್ನು ಹುಡುಕಿ",
  search_news: "ಎಲ್ಲಾ ಸುದ್ದಿಗಳನ್ನು ಹುಡುಕಿ",
  search_events: "ಎಲ್ಲಾ ಕಾರ್ಯಕ್ರಮಗಳನ್ನು ಹುಡುಕಿ",
  search_notices: "ಎಲ್ಲಾ ಅವಿಷ್ಕಾರಗಳನ್ನು ಹುಡುಕಿ",
  search_categories: "ಎಲ್ಲಾ ವರ್ಗಗಳನ್ನು ಹುಡುಕಿ",
  search_users: "ಎಲ್ಲಾ ಬಳಕೆದಾರರನ್ನು ಹುಡುಕಿ",
  search_disputes: "ಎಲ್ಲಾ ವ್ಯಾಜ್ಯಗಳನ್ನು ಹುಡುಕಿ",
  search_expenses: "ಎಲ್ಲಾ ಖರ್ಚುಗಳನ್ನು ಹುಡುಕಿ",
  search_financial_reports: "ಎಲ್ಲಾ ಹಣಕಾಸು ವರದಿಗಳನ್ನು ಹುಡುಕಿ",
  search_donation: "ಎಲ್ಲಾ ದೇಣಿಗೆಗಳನ್ನು ಹುಡುಕಿ",
  search_punyarjak: "ಎಲ್ಲಾ ಪುಣ್ಯರ್ಜಕಗಳನ್ನು ಹುಡುಕಿ",
  search_donation_box_collection: "ದೇಣಿಗೆ ಪೆಟ್ಟಿಗೆ ಸಂಗ್ರಹಗಳನ್ನು ಹುಡುಕಿ",
  search_commitment: "ಎಲ್ಲಾ ಬದ್ಧತೆಗಳನ್ನು ಹುಡುಕಿ",
};
